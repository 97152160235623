import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { FormInput } from '@components/form_input';
import { SelectOption } from '@components/selects/single_select';
import { SubmitButton } from '@components/submit_button';
import { showToast } from '@components/toast';
import { MessageType } from '@project/enums';
import { removeEmptyFields, validateEmail, validateName } from '@utils/helpers';

import { AgentSwitcher } from './agent_switcher';

type Props = {
  dropDown: JSX.Element;
  isSwitched: boolean;
  setIsSwitched: Dispatch<SetStateAction<boolean>>;
  createNewAgent: (
    payload: CreateNewAgentDto,
  ) => DispatchWithPayload<CreateNewAgentDto>;
  createNewAgency: (
    payload: CreateNewAgencyDto,
  ) => DispatchWithPayload<CreateNewAgencyDto>;
  agencies: Agency[];
  onSearchChanged: (name: string, value: string) => void;
  searchTerm: { search: string };
};

export function AddPanel({
  dropDown,
  isSwitched,
  setIsSwitched,
  createNewAgent,
  createNewAgency,
  agencies,
  onSearchChanged,
  searchTerm,
}: Props): JSX.Element {
  const [newAgent, setNewAgent] = useState<
    CreateNewAgentDto | CreateNewAgencyDto
  >();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  console.log(errors, 'errors');
  useEffect(() => {
    setNewAgent(undefined);
    setErrors({});
  }, [isSwitched]);

  const onCreateNewAgentChanged = async (
    name: string,
    value: string | SelectOption | null,
  ) => {
    let newValue: string | number | undefined | SelectOption | null = value;

    if (name === 'agencyId' && value && typeof value === 'object') {
      newValue = value.id;
    }

    const editedNewAgent: CreateNewAgentDto | CreateNewAgencyDto = {
      ...(newAgent ??
        (isSwitched ? { name: '', email: '' } : { fullName: '', email: '' })),
      [name]: newValue,
    };

    setNewAgent(editedNewAgent);
  };

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (isSwitched) {
      if (
        !(newAgent as CreateNewAgencyDto)?.name ||
        !validateName((newAgent as CreateNewAgencyDto).name)
      ) {
        newErrors.name = 'Name is invalid.';
      }
      const refSymbols = (newAgent as CreateNewAgencyDto)?.refSymbols;
      if (!refSymbols || refSymbols.length < 3 || refSymbols.length > 5) {
        newErrors.refSymbols =
          'Ref Symbols must be between 3 and 5 characters.';
      }
    }

    if (!newAgent?.email || !validateEmail(newAgent.email)) {
      newErrors.email = 'Email is invalid.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSubmit = () => {
    if (validateForm()) {
      !isSwitched
        ? createNewAgent(removeEmptyFields(newAgent) as CreateNewAgentDto)
        : createNewAgency(removeEmptyFields(newAgent) as CreateNewAgencyDto);
    } else {
      showToast({
        type: MessageType.ERROR,
        message: Object.values(errors).join(' '),
      });
    }
  };

  return (
    <div className="add-panel">
      <form>
        {!isSwitched ? (
          <FormInput
            type="text"
            label="Full Name"
            name={'fullName'}
            placeholder="Michael Jane"
            value={(newAgent as CreateNewAgentDto)?.fullName || ''}
            onValueChanged={(name: string, value: string) => {
              if (onCreateNewAgentChanged) {
                onCreateNewAgentChanged(name, value);
              }
            }}
            error={errors.fullName}
          />
        ) : (
          <FormInput
            type="text"
            label="Name"
            name={'name'}
            placeholder="Michael Jane"
            value={(newAgent as CreateNewAgencyDto)?.name || ''}
            onValueChanged={(name: string, value: string) => {
              if (onCreateNewAgentChanged) {
                onCreateNewAgentChanged(name, value);
              }
            }}
            error={errors.name}
          />
        )}
        <FormInput
          type="text"
          label="Email"
          name="email"
          placeholder="michael.jane@gmail.com"
          value={newAgent?.email || ''}
          onValueChanged={(name: string, value: string) => {
            if (onCreateNewAgentChanged) {
              onCreateNewAgentChanged(name, value);
            }
          }}
          error={errors.email}
        />
        <AgentSwitcher
          onCreateNewAgentChanged={onCreateNewAgentChanged}
          newAgent={newAgent}
          isSwitched={isSwitched}
          setIsSwitched={setIsSwitched}
          agencies={agencies}
        />
        <SubmitButton onClick={() => onSubmit()} />
      </form>
      {dropDown ? dropDown : null}
      <FormInput
        extraClass="properties"
        type="text"
        widget={
          <>
            <img
              src={require('@assets/svg/icons/search.svg').default}
              alt="search"
            />
          </>
        }
        name="search"
        value={searchTerm.search}
        onValueChanged={onSearchChanged}
        placeholder="Search"
      />
    </div>
  );
}
