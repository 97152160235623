import { useState } from 'react';

import { AgencyCardSocial } from './agency_card_social';
import { AgencyContent } from './agency_content';
import { AgencyForm } from './agency_form';
import { AgencyInfo } from './agency_info';

type AgencyCardProps = {
  agency: Agency;
  removeAgencyAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  addAgencyAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  removeAgency: (payload: Agency) => DispatchWithPayload<Agency>;
  updateAgency: (payload: Agency) => DispatchWithPayload<Agency>;
  addAgencyDocument: (payload: Agency) => DispatchWithPayload<Agency>;
  removeAgencyDocument: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
};

export function AgencyCard({
  agency,
  addAgencyAvatar,
  removeAgencyAvatar,
  removeAgency,
  updateAgency,
  addAgencyDocument,
  removeAgencyDocument,
}: AgencyCardProps): JSX.Element {
  const [isOpened, setIsOpened] = useState<AccordionState>({
    main: false,
    docs: false,
    preferences: false,
  });

  const handleToggleSection = (section: keyof AccordionState) => {
    setIsOpened((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="agents-card__layout">
      <div className={`main-section ${isOpened.main ? 'open' : ''}`}>
        <div className="head" onClick={() => handleToggleSection('main')}>
          <div className="head-user">
            <img
              src={require('@assets/svg/icons/arrow-down.svg').default}
              alt="arrow-down"
              className={`arrow-icon ${isOpened.main ? 'opened' : ''}`}
            />
            <AgencyInfo agency={agency} />
          </div>
          <AgencyCardSocial removeAgency={removeAgency} agency={agency} />
        </div>
        <div className={`data-content ${isOpened.main ? 'open' : ''}`}>
          {isOpened.main && (
            <>
              <div>
                <AgencyForm
                  agency={agency}
                  addAgencyAvatar={addAgencyAvatar}
                  removeAgencyAvatar={removeAgencyAvatar}
                  updateAgency={updateAgency}
                />
              </div>
              <AgencyContent
                isOpened={isOpened}
                handleToggleSection={handleToggleSection}
                agency={agency}
                updateAgency={updateAgency}
                addAgencyDocument={addAgencyDocument}
                removeAgencyDocument={removeAgencyDocument}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
