export function AgencyInfo({ agency }: { agency: Agency }) {
  return (
    <div className="person-data">
      <div className="person-data__photo">
        <img
          src={
            agency?.avatar || require('@assets/svg/icons/profile.svg').default
          }
          alt="profile"
        />
      </div>
      <div className="person-data__details">
        <span className="name">{agency?.name}</span>
      </div>

      <div className="person-data__role person-data__role_violet">Agency</div>
    </div>
  );
}
