import { FileUploader } from '@components/file_uploader/file_uploader';

export function Docs({
  title,
  addUserDocument,
  user,
  removeDocument,
}: DocsProps) {
  const handleFileUpload = (file: File | File[]) => {
    /*@ts-ignore*/
    addUserDocument({ type: user.type, id: user.id, documents: file });
  };

  const handleRemoveDocument = (documentURL: string) => {
    /*@ts-ignore*/
    removeDocument({ type: user.type, id: user.id, url: documentURL });
  };

  return (
    <div style={{ display: 'flex' }}>
      <div className="agent-docs">
        <label htmlFor="docs" className="agent-docs__label">
          <img
            src={require('@assets/svg/icons/download-outline.svg').default}
            alt="download"
          />
          <span>{title}</span>
        </label>

        <div
          style={{
            display: 'flex',
            gap: '8px',
            width: '100%',
            flexWrap: 'wrap',
          }}
        >
          {user?.documents &&
            user.documents.length > 0 &&
            user.documents.map((document) => (
              <div key={document} style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    left: '70%',
                    top: '-10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    backgroundColor: 'blanchedalmond',
                  }}
                >
                  <img
                    style={{ width: '16px', height: '16px' }}
                    onClick={() => handleRemoveDocument(document)}
                    src={
                      require('@assets/svg/icons/delete_presentation.svg')
                        .default
                    }
                  />
                </div>
                <img
                  style={{
                    maxWidth: '64px',
                    maxHeight: '64px',
                    borderRadius: '8px',
                  }}
                  src={document}
                  alt="doc"
                />
              </div>
            ))}
          <FileUploader onFileUpload={handleFileUpload}>
            <button className="download-button">
              <img
                src={require('@assets/svg/icons/plus_black.svg').default}
                alt="plus"
              />
            </button>
          </FileUploader>
        </div>
      </div>
    </div>
  );
}
