import { AddFormCustomers } from './add_form_customers';
import { CustomerFilters } from './customers_filters';

export const CustomersPanel = ({
  searchTerm,
  onSearchChanged,
  handleFilterChange,
  agentsAndAgencies,
  createNewCustomer,
}: any) => {
  return (
    <div className="customers-panel">
      <AddFormCustomers
        agentsAndAgencies={agentsAndAgencies}
        createNewCustomer={createNewCustomer}
      />
      <CustomerFilters
        searchTerm={searchTerm}
        onSearchChanged={onSearchChanged}
        handleFilterChange={handleFilterChange}
      />
    </div>
  );
};
