import { ReduxActionType } from '@project/enums';
import {
  updateAgentOrAgency,
  removeAgentOrAgencyAvatar,
  removeAgentOrAgencyDocument,
  filterAgentOrAgency,
} from '@utils/helpers';

const initialState: AgentsState = {
  agents: [],
  isLoading: false,
  isLoadingStatusAgent: false,
};

export function agentsReducer(
  state: AgentsState = initialState,
  action: DispatchWithPayload<(Agent | Agency)[]>,
): AgentsState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.AGENTS_REQUEST_GET_ALL_AGENTS_AND_AGENCIES:
      return {
        ...state,
        isLoading: true,
      };
    case ReduxActionType.AGENTS_AND_AGENCIES_SET_DATA:
      return {
        ...state,
        agents: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CREATE_NEW_AGENT_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    // case ReduxActionType.REQUEST_DELETE_AGENT:
    //   return {
    //     ...state,
    //     describe loading state + on success
    //   };
    case ReduxActionType.AGENTS_REQUEST_ADD_AVATAR:
      return {
        ...state,
        // agents: action.payload,
        isLoadingStatusAgent: true,
      };
    case ReduxActionType.AGENTS_REQUEST_ADD_AVATAR_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    case ReduxActionType.AGENT_REQUEST_REMOVE_AVATAR:
      return {
        ...state, // TODO: set avatar to null
        agents: removeAgentOrAgencyAvatar(state.agents, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
        }),
      };
    case ReduxActionType.AGENT_REQUEST_UPDATE:
      return {
        ...state,
        /*@ts-ignore*/
        agents: updateAgentOrAgency(state.agents, action.payload),
        isLoadingStatusAgent: true,
      };

    case ReduxActionType.AGENT_SUCCESS_UPDATE:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };

    case ReduxActionType.AGENTS_REQUEST_ADD_DOCUMENTS:
      return {
        ...state,
        // agents: action.payload,
        isLoadingStatusAgent: true,
      };
    case ReduxActionType.AGENTS_REQUEST_ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };

    case ReduxActionType.AGENT_REQUEST_REMOVE_DOCUMENT:
      return {
        ...state,
        agents: removeAgentOrAgencyDocument(state.agents, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
          /*@ts-ignore*/
          url: action.payload.url,
        }),
      };

    //AGENCY!

    case ReduxActionType.CREATE_NEW_AGENCY_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    case ReduxActionType.AGENCY_REQUEST_ADD_AVATAR:
      return {
        ...state,
        isLoadingStatusAgent: true,
      };
    case ReduxActionType.AGENCY_REQUEST_ADD_AVATAR_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    case ReduxActionType.AGENCY_REQUEST_REMOVE_AVATAR:
      return {
        ...state,
        agents: removeAgentOrAgencyAvatar(state.agents, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
        }),
      };
    case ReduxActionType.AGENCY_REQUEST_UPDATE:
      return {
        ...state,
        /*@ts-ignore*/
        agents: updateAgentOrAgency(state.agents, action.payload),
        isLoadingStatusAgent: true,
      };

    case ReduxActionType.AGENCY_SUCCESS_UPDATE:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    case ReduxActionType.AGENCY_REQUEST_ADD_DOCUMENTS:
      return {
        ...state,
        // agents: action.payload,
        isLoadingStatusAgent: true,
      };
    case ReduxActionType.AGENCY_REQUEST_ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        agents: action.payload,
        isLoadingStatusAgent: false,
      };
    case ReduxActionType.AGENCY_REQUEST_REMOVE_DOCUMENT:
      return {
        ...state,
        agents: removeAgentOrAgencyDocument(state.agents, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
          /*@ts-ignore*/
          url: action.payload.url,
        }),
      };
  }
}

export class AgentsAction {
  public static requestCreateNewAgent(
    payload: CreateNewAgentDto,
  ): DispatchWithPayload<CreateNewAgentDto> {
    return {
      type: ReduxActionType.AGENTS_REQUEST_CREATE_NEW_AGENT,
      payload,
    };
  }
  public static createNewAgentSuccess(
    payload: CreateNewAgentDto,
  ): DispatchWithPayload<CreateNewAgentDto> {
    return {
      type: ReduxActionType.CREATE_NEW_AGENT_SUCCESS,
      payload,
    };
  }
  public static getAgents(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.AGENTS_REQUEST_GET_ALL_AGENTS_AND_AGENCIES,
    };
  }
  public static setAgentsAndAgencies(
    payload: Agent[] | Agency[],
  ): DispatchWithPayload<Agent[] | Agency[]> {
    return {
      type: ReduxActionType.AGENTS_AND_AGENCIES_SET_DATA,
      payload,
    };
  }
  public static removeAgent(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.REQUEST_DELETE_AGENT,
      payload,
    };
  }
  public static updateAgentAvatar(
    payload: ExactAgentOrAgency,
  ): DispatchWithPayload<ExactAgentOrAgency> {
    return {
      type: ReduxActionType.AGENTS_REQUEST_ADD_AVATAR,
      payload,
    };
  }
  public static updateAgentAvatarSuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENTS_REQUEST_ADD_AVATAR_SUCCESS,
      payload,
    };
  }
  public static removeAgentAvatar(
    payload: ExactAgentOrAgency,
  ): DispatchWithPayload<ExactAgentOrAgency> {
    return {
      type: ReduxActionType.AGENT_REQUEST_REMOVE_AVATAR,
      payload,
    };
  }
  public static updateAgent(payload: Agent): DispatchWithPayload<Agent> {
    return {
      type: ReduxActionType.AGENT_REQUEST_UPDATE,
      payload,
    };
  }
  public static updateAgentSuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENT_SUCCESS_UPDATE,
      payload,
    };
  }

  public static addAgentsDocuments(payload: Agent): DispatchWithPayload<Agent> {
    return {
      type: ReduxActionType.AGENTS_REQUEST_ADD_DOCUMENTS,
      payload,
    };
  }
  public static addAgentsDocumentsSuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENTS_REQUEST_ADD_DOCUMENTS_SUCCESS,
      payload,
    };
  }

  public static removeAgentDocument(
    payload: ExactPartner,
  ): DispatchWithPayload<ExactPartner> {
    return {
      type: ReduxActionType.AGENT_REQUEST_REMOVE_DOCUMENT,
      payload,
    };
  }

  //AGENCY
  public static requestCreateNewAgency(
    payload: CreateNewAgencyDto,
  ): DispatchWithPayload<CreateNewAgencyDto> {
    return {
      type: ReduxActionType.REQUEST_CREATE_NEW_AGENCY,
      payload,
    };
  }
  public static createNewAgencySuccess(
    payload: CreateNewAgencyDto,
  ): DispatchWithPayload<CreateNewAgencyDto> {
    return {
      type: ReduxActionType.CREATE_NEW_AGENCY_SUCCESS,
      payload,
    };
  }

  public static removeAgency(payload: Agency): DispatchWithPayload<Agency> {
    return {
      type: ReduxActionType.REQUEST_DELETE_AGENCY,
      payload,
    };
  }

  public static updateAgencyAvatar(
    payload: ExactAgentOrAgency,
  ): DispatchWithPayload<ExactAgentOrAgency> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_ADD_AVATAR,
      payload,
    };
  }
  public static updateAgencyAvatarSuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_ADD_AVATAR_SUCCESS,
      payload,
    };
  }
  public static removeAgencyAvatar(
    payload: ExactAgentOrAgency,
  ): DispatchWithPayload<ExactAgentOrAgency> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_REMOVE_AVATAR,
      payload,
    };
  }

  public static updateAgency(payload: Agency): DispatchWithPayload<Agency> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_UPDATE,
      payload,
    };
  }
  public static updateAgencySuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENCY_SUCCESS_UPDATE,
      payload,
    };
  }

  public static addAgencyDocuments(
    payload: Agency,
  ): DispatchWithPayload<Agency> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_ADD_DOCUMENTS,
      payload,
    };
  }
  public static addAgencyDocumentsSuccess(
    payload: (Agent | Agency)[],
  ): DispatchWithPayload<(Agent | Agency)[]> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_ADD_DOCUMENTS_SUCCESS,
      payload,
    };
  }

  public static removeAgencyDocument(
    payload: ExactAgentOrAgency,
  ): DispatchWithPayload<ExactAgentOrAgency> {
    return {
      type: ReduxActionType.AGENCY_REQUEST_REMOVE_DOCUMENT,
      payload,
    };
  }

  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_RESET_DATA,
    };
  }
}
