import { PropertyType } from '@project/enums';

export function toPrice(value: number): string {
  const priceData = value.toFixed(0);
  const priceIntegerPart = parseInt(priceData)
    .toLocaleString('en-GB')
    .replace(/,/g, '.');

  return `${priceIntegerPart}`;
}

export const isValidYouTubeUrl = (url: string) => {
  const regex = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
  return regex.test(url);
};

export const getPropertyStatusLabel = (
  status: number,
  price?: number | null,
  currencyCode?: string,
): React.ReactNode => {
  switch (status) {
    case 1:
      return 'Sold';
    case 2:
      return 'Long term';
    case 3:
      return 'Secret';
    case 4:
      return 'Archive';
    case 5:
      return price ? `${toPrice(price)} ${currencyCode}` : 'On the market';
    case 6:
      return 'Private';
    case 7:
      return 'Only for customers';
    case 8:
      return 'Only for agents';
    default:
      return 'Unknown status';
  }
};

export const stripHtmlTags = (str: string) => {
  return str?.replace(/<\/?[^>]+(>|$)|[\/"']/g, '');
};

export const adaptPropertyToSend = (body: UpdateProperty) => {
  return {
    ...body,
    id: body.id,
    address: body.address || null,
    price: body.price || null,
    virtualTourUrl: body.virtualTourUrl || null,
    youtubeUrl: body.youtubeUrl || null,
    buildArea: body.buildArea || null,
    landArea: body.landArea || null,
    bathrooms: body.bathrooms || null,
    bedrooms: body.bedrooms || null,
    yearOfBuild: body.yearOfBuild || null,
  };
};

export const adaptAgentToSend = (body: Agent) => {
  return {
    ...body,
    id: body.id,
    fullName: body.fullName || null,
    email: body.email || null,
    birthday: body.birthday || null,
    nationality: body.nationality || null,
    phoneNumber: body.phoneNumber || null,
    whatsAppNumber: body.whatsAppNumber || null,
    telegramUserName: body.telegramUserName || null,
  };
};

export const adaptAgencyToSend = (body: Agency) => {
  return {
    ...body,
    id: body.id,
    name: body.name || null,
    email: body.email || null,
    website: body.website || null,
    instagramUrl: body.instagramUrl || null,
    facebookUrl: body.facebookUrl || null,
    refSymbols: body.refSymbols || null,
    nationality: body.nationality || null,
    phoneNumber: body.phoneNumber || null,
  };
};

export const adaptCustomerToSend = (body: Customer) => {
  return {
    ...body,
    id: body.id,
    fullName: body.fullName || null,
    email: body.email || null,
    birthday: body.birthday || null,
    nationality: body.nationality || null,
    phoneNumber: body.phoneNumber || null,
    whatsAppNumber: body.whatsAppNumber || null,
    telegramUserName: body.telegramUserName || null,
    types: body.types ? body.types.filter((type) => type.id && type.name) : [],
    paymentMethods: body.paymentMethods
      ? body.paymentMethods.filter((method) => method.id && method.name)
      : [],
  };
};

//REDUX - REDUCERS - AGENTS | AGENCIES FUNC

export function updateAgentOrAgency(
  data: (Agent | Agency)[],
  updatedData: Partial<Agent | Agency>,
): (Agent | Agency)[] {
  return data.map((item) =>
    item.id === updatedData.id && item.type === updatedData.type
      ? { ...item, ...updatedData }
      : item,
  );
}

// Вспомогательная функция для удаления аватара у агента
export function removeAgentOrAgencyAvatar(
  data: (Agent | Agency)[],
  { id, type }: { id: number; type: number },
): (Agent | Agency)[] {
  return data.map((item) =>
    item.id === id && item.type === type ? { ...item, avatar: null } : item,
  );
}

// Вспомогательная функция для удаления документа у агента
export function removeAgentOrAgencyDocument(
  data: (Agent | Agency)[],
  { id, type, url }: { id: number; type: number; url: string },
): (Agent | Agency)[] {
  return data.map((item) =>
    item.id === id && item.type === type
      ? {
          ...item,
          documents: item?.documents?.filter((doc) => doc !== url) || [],
        }
      : item,
  );
}

export function filterAgentOrAgency(
  data: (Agent | Agency)[],
  { id, type }: { id: number; type: number },
): (Agent | Agency)[] {
  return data.filter((item) => item.id !== id || item.type !== type);
}

export function filterCustomer(
  data: Customer[],
  { id }: { id: number },
): Customer[] {
  return data.filter((item) => item.id !== id);
}

export function updateCustomer(
  data: Customer[],
  updatedData: Partial<Customer>,
): Customer[] {
  return data.map((item) =>
    item.id === updatedData.id ? { ...item, ...updatedData } : item,
  );
}

export function removeCustomerAvatar(
  data: Customer[],
  { id }: { id: number },
): Customer[] {
  return data.map((item) =>
    item.id === id ? { ...item, avatar: null } : item,
  );
}

export function removeCustomerDocument(
  data: Customer[],
  { id, url }: { id: number; url: string },
): Customer[] {
  return data.map((item) =>
    item.id === id
      ? {
          ...item,
          documents: item?.documents?.filter((doc) => doc !== url) || [],
        }
      : item,
  );
}

export function removeEmptyFields(obj: any): any {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    const value = newObj[key];
    if (value === null || value === '') {
      delete newObj[key];
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      newObj[key] = removeEmptyFields(value);
      if (Object.keys(newObj[key]).length === 0) {
        delete newObj[key];
      }
    }
  });

  return newObj;
}

export const validateEmail = (email?: string | null) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return email && emailRegex.test(email);
};

export const validateName = (name?: string | null) => {
  const nameRegex = /^([a-zA-Z-]{2,})$/;
  return name && nameRegex.test(name);
};
