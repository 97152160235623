import dayjs from 'dayjs';

export function AgentInfo({ agent }: { agent: Agent }): JSX.Element {
  return (
    <div className="person-data">
      <div className="person-data__photo">
        <img
          src={
            agent?.avatar || require('@assets/svg/icons/profile.svg').default
          }
          alt="profile"
        />
      </div>
      <div className="person-data__details">
        <span className="name">{agent?.fullName}</span>
        {agent?.birthday && (
          <span className="birthday">{`🎉 ${dayjs(agent.birthday).format(
            'D MMM',
          )}`}</span>
        )}
      </div>

      <div className="person-data__role">
        Agent {agent?.agency?.name && `in ${agent.agency.name}`}
      </div>
    </div>
  );
}
