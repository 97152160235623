import dayjs from 'dayjs';

export const CustomerInfo = ({ customer }: { customer: Customer }) => {
  function renderCustomerRoles(customer: Customer) {
    const roles = ['Buyer', 'Vendor'];
    return roles
      .filter((role) => customer?.types?.some((type) => type.name === role))
      .map((role, index) => (
        <div
          key={`${role}+${index}`}
          className={`customer-role customer-role__${role.toLowerCase()}`}
        >
          {role.toUpperCase()}
        </div>
      ));
  }

  return (
    <div className="customer-info-container">
      <div className="person-data__photo">
        <img
          src={
            customer?.avatar || require('@assets/svg/icons/profile.svg').default
          }
          alt="profile"
        />
      </div>
      <div className="person-data__details">
        <span className="name">{customer?.fullName}</span>
        {customer?.birthday && (
          <span className="birthday">{`🎉 ${dayjs(customer.birthday).format(
            'D MMM',
          )}`}</span>
        )}
      </div>
      {renderCustomerRoles(customer)}
      {customer?.paymentMethods && customer?.paymentMethods?.length > 1 && (
        <div className="person-data__audocat">
          <img
            src={require('@assets/svg/icons/building.svg').default}
            alt="building"
          />
          <span>{`${customer.paymentMethods?.map(
            (paymentMethod) => paymentMethod.name,
          )}`}</span>
        </div>
      )}
    </div>
  );
};
