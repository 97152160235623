import { useState } from 'react';

import { CustomerContent } from './customer_content';
import { CustomerInfo } from './customer_info';
import { CustomerSocial } from './customer_social';
import { CustomersForm } from './customers_form';

type AccordionState = {
  main: boolean;
  docs: boolean;
  preferences: boolean;
  note: boolean;
  history: boolean;
};

export const CustomersCard = ({
  removeCustomerAvatar,
  addCustomerAvatar,
  customer,
  removeCustomer,
  updateCustomer,
  addCustomerDocument,
  removeCustomerDocument,
}: any) => {
  const [isOpened, setIsOpened] = useState<AccordionState>({
    main: false,
    docs: false,
    preferences: false,
    note: false,
    history: false,
  });

  const handleToggleSection = (section: keyof AccordionState) => {
    setIsOpened((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  return (
    <div className="customers-card__layout">
      <div className={`main-section ${isOpened.main ? 'open' : ''}`}>
        <div className="head" onClick={() => handleToggleSection('main')}>
          <div className="head-user">
            <img
              src={require('@assets/svg/icons/arrow-down.svg').default}
              alt="arrow-down"
              className={`arrow-icon ${isOpened.main ? 'opened' : ''}`}
            />
            <CustomerInfo customer={customer} />
          </div>
          <CustomerSocial customer={customer} removeCustomer={removeCustomer} />
        </div>
        <div className={`data-content ${isOpened.main ? 'open' : ''}`}>
          {isOpened.main && (
            <>
              <div>
                <CustomersForm
                  customer={customer}
                  removeCustomerAvatar={removeCustomerAvatar}
                  addCustomerAvatar={addCustomerAvatar}
                  updateCustomer={updateCustomer}
                />
              </div>
              <CustomerContent
                addCustomerDocument={addCustomerDocument}
                removeCustomerDocument={removeCustomerDocument}
                updateCustomer={updateCustomer}
                customer={customer}
                isOpened={isOpened}
                handleToggleSection={handleToggleSection}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
