import { ForkEffect, takeEvery, takeLatest } from 'redux-saga/effects';

import { ReduxActionType } from '@project/enums';
import {
  logoutManagerWorker,
  signInManagerWorker,
} from '@store/side_effects/managers';
import {
  createNewPartner,
  getAllPartners,
  removePartnerAvatarById,
  removePartnerById,
  updatePartnerById,
} from '@store/side_effects/partners';
import { customerTotalStatsWorker } from '@store/side_effects/stats';

//TODO: path
import {
  AddAgencyDocumentsById,
  AddAgentsDocumentsById,
  createNewAgency,
  createNewAgent,
  getAllAgentsAndAgencies,
  removeAgencyAvatar,
  removeAgencyById,
  removeAgencyDocument,
  removeAgentAvatar,
  removeAgentById,
  removeAgentDocument,
  updateAgencyAvatarById,
  updateAgencyById,
  updateAgentAvatarById,
  updateAgentById,
} from './agents';
import {
  AddCustomersDocumentsById,
  createNewCustomer,
  getAllCustomers,
  removeCustomerAvatar,
  removeCustomerById,
  removeCustomerDocument,
  updateCustomerAvatarById,
  updateCustomerById,
} from './customers';
import { getAllProperties, removePropertyById } from './properties';
import {
  createNewProperty,
  getPropertyId,
  removeProperty,
  updatePropertyById,
  updatePropertyPhotoById,
} from './property';

export function* rootSagaWatcher(): Generator<ForkEffect<void>> {
  //PARTNERS
  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_DELETE_PARTNER,
    removePartnerById,
  );

  yield takeEvery(
    ReduxActionType.PARTNER_REQUEST_REMOVE_AVATAR,
    removePartnerAvatarById,
  );

  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_CREATE_NEW_PARTNER,
    createNewPartner,
  );

  yield takeLatest(
    ReduxActionType.PARTNERS_REQUEST_UPDATE_PARTNER,
    updatePartnerById,
  );
  yield takeEvery(
    ReduxActionType.PARTNERS_REQUEST_GET_ALL_PARTNERS,
    getAllPartners,
  );
  //MANAGERS
  yield takeEvery(
    ReduxActionType.MANAGERS_REQUEST_SIGN_IN,
    signInManagerWorker,
  );

  yield takeEvery(ReduxActionType.MANAGERS_REQUEST_LOGOUT, logoutManagerWorker);

  //STATS_REQUEST_CUSTOMERS_TOTAL
  yield takeEvery(
    ReduxActionType.STATS_REQUEST_CUSTOMERS_TOTAL,
    customerTotalStatsWorker,
  );

  //PROPERTIES
  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_GET_ALL_PROPERTIES,
    getAllProperties,
  );

  yield takeLatest(
    ReduxActionType.PROPERTIES_UPDATE_PROPERTY,
    updatePropertyById,
  );

  yield takeEvery(
    ReduxActionType.PROPERTIES_REQUEST_DELETE_PROPERTY,
    removePropertyById,
  );
  //PROPERTY
  yield takeEvery(
    ReduxActionType.PROPERTY_REQUEST_CREATE_NEW_PROPERTY,
    createNewProperty,
  );

  yield takeLatest(ReduxActionType.PROPERTY_REQUEST_UPDATE, updatePropertyById);

  yield takeEvery(ReduxActionType.PROPERTY_REQUEST_GET_PROPERTY, getPropertyId);

  yield takeLatest(
    ReduxActionType.PROPERTY_REQUEST_ADD_PHOTO,
    updatePropertyPhotoById,
  );
  yield takeEvery(ReduxActionType.PROPERTY_REQUEST_REMOVE, removeProperty);

  //AGENTS
  yield takeEvery(
    ReduxActionType.AGENTS_REQUEST_GET_ALL_AGENTS_AND_AGENCIES,
    getAllAgentsAndAgencies,
  );

  yield takeEvery(
    ReduxActionType.AGENTS_REQUEST_CREATE_NEW_AGENT,
    createNewAgent,
  );

  yield takeEvery(ReduxActionType.REQUEST_DELETE_AGENT, removeAgentById);

  yield takeLatest(
    ReduxActionType.AGENTS_REQUEST_ADD_AVATAR,
    updateAgentAvatarById,
  );

  yield takeEvery(
    ReduxActionType.AGENT_REQUEST_REMOVE_AVATAR,
    removeAgentAvatar,
  );

  yield takeLatest(ReduxActionType.AGENT_REQUEST_UPDATE, updateAgentById);

  yield takeLatest(
    ReduxActionType.AGENTS_REQUEST_ADD_DOCUMENTS,
    AddAgentsDocumentsById,
  );

  yield takeEvery(
    ReduxActionType.AGENT_REQUEST_REMOVE_DOCUMENT,
    removeAgentDocument,
  );

  //AGENCIES
  yield takeEvery(ReduxActionType.REQUEST_CREATE_NEW_AGENCY, createNewAgency);

  yield takeEvery(ReduxActionType.REQUEST_DELETE_AGENCY, removeAgencyById);

  yield takeLatest(
    ReduxActionType.AGENCY_REQUEST_ADD_AVATAR,
    updateAgencyAvatarById,
  );

  yield takeEvery(
    ReduxActionType.AGENCY_REQUEST_REMOVE_AVATAR,
    removeAgencyAvatar,
  );

  yield takeLatest(ReduxActionType.AGENCY_REQUEST_UPDATE, updateAgencyById);

  yield takeLatest(
    ReduxActionType.AGENCY_REQUEST_ADD_DOCUMENTS,
    AddAgencyDocumentsById,
  );

  yield takeEvery(
    ReduxActionType.AGENCY_REQUEST_REMOVE_DOCUMENT,
    removeAgencyDocument,
  );

  //CUSTOMERS
  yield takeEvery(ReduxActionType.CUSTOMERS_REQUEST_GET_ALL, getAllCustomers);

  yield takeEvery(
    ReduxActionType.CUSTOMERS_CREATE_REQUEST_NEW_CUSTOMER,
    createNewCustomer,
  );
  yield takeEvery(ReduxActionType.REQUEST_DELETE_CUSTOMER, removeCustomerById);

  yield takeLatest(
    ReduxActionType.CUSTOMERS_REQUEST_ADD_AVATAR,
    updateCustomerAvatarById,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMERS_REQUEST_REMOVE_AVATAR,
    removeCustomerAvatar,
  );

  yield takeLatest(ReduxActionType.CUSTOMER_REQUEST_UPDATE, updateCustomerById);

  yield takeLatest(
    ReduxActionType.CUSTOMER_REQUEST_ADD_DOCUMENTS,
    AddCustomersDocumentsById,
  );

  yield takeEvery(
    ReduxActionType.CUSTOMER_REQUEST_REMOVE_DOCUMENT,
    removeCustomerDocument,
  );
}
