import { useRef, useState, useEffect, Fragment } from 'react';

import { Preferences } from '@components/sections/preferences';

type AgencyPreferencesProps = {
  user: Customer | Agent | (Agent & { preferences: Preference[] });
  onUpdate: (payload: any) => DispatchWithPayload<any>;
  addNewPreference: boolean;
  resetAddNewPreferenceFlag: () => void;
};

export function PreferencesContainer({
  user,
  onUpdate,
  addNewPreference,
  resetAddNewPreferenceFlag,
}: AgencyPreferencesProps) {
  const timeoutRef = useRef<number | null>(null);

  const [preferences, setPreferences] = useState(
    Array.isArray(user?.preferences) && user.preferences.length
      ? user.preferences
      : [
          {
            location: null,
            type: undefined,
            to: undefined,
            from: null,
            exactly: false,
          },
        ],
  );

  useEffect(() => {
    if (addNewPreference) {
      setPreferences([
        ...preferences,
        {
          location: null,
          type: undefined,
          to: undefined,
          from: null,
          exactly: false,
        },
      ]);
      resetAddNewPreferenceFlag();
    }
  }, [addNewPreference, preferences, resetAddNewPreferenceFlag]);

  const onPreferencesChanged = async (
    index: number,
    name: string,
    value: any,
  ) => {
    let newValue = value;

    const updatedPreferences = [...preferences];
    updatedPreferences[index] = {
      ...updatedPreferences[index],
      [name]: newValue,
    };
    setPreferences(updatedPreferences);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    /*@ts-ignore*/
    timeoutRef.current = setTimeout(() => {
      onUpdate({
        /*@ts-ignore*/
        type: user.type,
        id: user.id,
        preferences: updatedPreferences,
      });
    }, 2000);
  };

  return (
    <>
      {preferences &&
        preferences.map((preference, index) => (
          <Fragment key={index}>
            <Preferences
              index={index}
              preference={preference}
              onPreferencesChanged={onPreferencesChanged}
            />
            {index < preferences.length - 1 && (
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#e0dbd5',
                  marginTop: '12px',
                }}
              />
            )}
          </Fragment>
        ))}
    </>
  );
}
