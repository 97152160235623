import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { FormAgentsDropDown } from '@components/form_agents_drop_down';
import { Navbar } from '@components/navbar';
import Spinner from '@components/spinner';
import { AgentsAction } from '@store/items/agents';
import { AgencyCard } from 'features/agency/agency_components/agency_card';
import { AddPanel } from 'features/agents/agents_components/add_panel';
import { AgentsCard } from 'features/agents/agents_components/agents_card';
import { DataNotFound } from 'features/partners/partners_components/data_not_found';

type Filters = {
  type: { id: number; name: string } | null;
};

const mapState = ({ agents }: RootState) => ({
  agentsAndAgencies: agents.agents,
  isLoading: agents.isLoading,
  isLoadingStatusAgent: agents.isLoadingStatusAgent,
});

const mapDispatch = {
  getAllAgentsAndAgencies: AgentsAction.getAgents,
  createNewAgent: AgentsAction.requestCreateNewAgent,
  removeAgent: AgentsAction.removeAgent,
  addAgentAvatar: AgentsAction.updateAgentAvatar,
  removeAgentAvatar: AgentsAction.removeAgentAvatar,
  updateAgent: AgentsAction.updateAgent,
  addAgentsDocument: AgentsAction.addAgentsDocuments,
  removeAgentDocument: AgentsAction.removeAgentDocument,

  createNewAgency: AgentsAction.requestCreateNewAgency,
  addAgencyAvatar: AgentsAction.updateAgencyAvatar,
  removeAgencyAvatar: AgentsAction.removeAgencyAvatar,
  removeAgency: AgentsAction.removeAgency,
  updateAgency: AgentsAction.updateAgency,
  addAgencyDocument: AgentsAction.addAgencyDocuments,
  removeAgencyDocument: AgentsAction.removeAgencyDocument,
};

export function Component({
  agentsAndAgencies,
  isLoading,
  isLoadingStatusAgent,
  getAllAgentsAndAgencies,
  createNewAgent,
  removeAgent,
  addAgentAvatar,
  removeAgentAvatar,
  updateAgent,
  addAgentsDocument,
  removeAgentDocument,

  createNewAgency,
  addAgencyAvatar,
  removeAgencyAvatar,
  removeAgency,
  updateAgency,
  addAgencyDocument,
  removeAgencyDocument,
}: AgentsPageProps): JSX.Element {
  useEffect(() => {
    getAllAgentsAndAgencies();
  }, []);

  const [isSwitched, setIsSwitched] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    type: null,
  });
  const [filteredAgentsAndAgencies, setFilteredAgentsAndAgencies] = useState<
    (Agent | Agency)[]
  >([]);
  const [searchTerm, setSearchTerm] = useState({
    search: '',
  });

  const handleFilterChange = (
    key: string,
    selectedOption: DropDownItem | null,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOption,
    }));
  };

  const applyFilters = (
    items: (Agent | Agency)[],
    filters: Filters,
  ): (Agent | Agency)[] => {
    return items.filter((item) => {
      if (filters.type && item.type !== filters.type.id) {
        return false;
      }
      return true;
    });
  };

  useEffect(() => {
    let filteredList = applyFilters(agentsAndAgencies || [], filters);
    if (searchTerm.search) {
      filteredList = filteredList.filter((item) =>
        item?.type === 1
          ? (item as Agent)?.fullName
              ?.toLowerCase()
              .includes(searchTerm.search.toLowerCase())
          : (item as Agency)?.name
              ?.toLowerCase()
              .includes(searchTerm.search.toLowerCase()),
      );
    }
    setFilteredAgentsAndAgencies(filteredList);
  }, [filters, searchTerm, agentsAndAgencies]);

  const onSearchChanged = (name: string, value: string) => {
    setSearchTerm({ ...searchTerm, [name]: value });
  };

  const agencies = agentsAndAgencies?.filter((agency) => agency.type === 2);
  const haveAgentsOrAgencies =
    filteredAgentsAndAgencies && filteredAgentsAndAgencies?.length < 1;
  return (
    <>
      {(isLoading || isLoadingStatusAgent) && <Spinner />}
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section className="agents-2">
        <AddPanel
          isSwitched={isSwitched}
          setIsSwitched={setIsSwitched}
          createNewAgent={createNewAgent}
          createNewAgency={createNewAgency}
          agencies={agencies}
          searchTerm={searchTerm}
          onSearchChanged={onSearchChanged}
          dropDown={
            <FormAgentsDropDown
              icon={require('@assets/svg/icons/all_agents.svg').default}
              title="All"
              onRemove={() => handleFilterChange('type', null)}
              onChange={(selectedOption) =>
                handleFilterChange('type', selectedOption)
              }
              options={[
                { id: 1, name: 'Agents' },
                { id: 2, name: 'Agencies' },
              ]}
            />
          }
        />
      </section>
      <section className="agents-card__section">
        {filteredAgentsAndAgencies?.map((agent) =>
          agent.type === 1 ? (
            <AgentsCard
              key={`${agent.id}+${agent.type}`}
              agent={agent as Agent}
              removeAgentAvatar={removeAgentAvatar}
              removeAgent={removeAgent}
              addAgentAvatar={addAgentAvatar}
              updateAgent={updateAgent}
              agencies={agencies}
              addAgentsDocument={addAgentsDocument}
              removeAgentDocument={removeAgentDocument}
            />
          ) : (
            <AgencyCard
              key={`${agent.id}+${agent.type}`}
              agency={agent as Agency}
              removeAgencyAvatar={removeAgencyAvatar}
              addAgencyAvatar={addAgencyAvatar}
              removeAgency={removeAgency}
              updateAgency={updateAgency}
              addAgencyDocument={addAgencyDocument}
              removeAgencyDocument={removeAgencyDocument}
            />
          ),
        )}
        {haveAgentsOrAgencies && (
          <DataNotFound
            subTitle={
              haveAgentsOrAgencies && !!searchTerm.search
                ? `There is no data by your request, try something else`
                : `Here still no one Agents or Agency was added, let's try`
            }
          />
        )}
      </section>
    </>
  );
}

export const AgentsPageConnector = connect(mapState, mapDispatch);

export const AgentsPage = AgentsPageConnector(Component);
