import { useState } from 'react';

import Modal from '@components/modal';

type AgentsCardSocialProps = {
  agent: Agent;
  removeAgent: (payload: ExactPartner) => DispatchWithPayload<ExactPartner>;
};

export function AgentsCardSocial({
  agent,
  removeAgent,
}: AgentsCardSocialProps): JSX.Element {
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  return (
    <>
      <div className="data-item__person-social-data">
        {agent.telegramUserName && (
          <a
            href={`https://t.me/${agent.telegramUserName}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(`https://t.me/${agent.telegramUserName}`, '_blank');
            }}
          >
            <img
              src={require('@assets/svg/icons/telegram.svg').default}
              alt="telegram"
            />
          </a>
        )}
        {agent.whatsAppNumber && (
          <a
            href={`https://wa.me/${agent.whatsAppNumber}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(`https://wa.me/${agent.whatsAppNumber}`, '_blank');
            }}
          >
            <img
              src={require('@assets/svg/icons/whatsapp.svg').default}
              alt="whatsapp"
            />
          </a>
        )}
        {agent.phoneNumber && (
          <a
            href={`tel:${agent.phoneNumber}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.location.href = `tel:${agent.phoneNumber}`;
            }}
          >
            <img
              src={require('@assets/svg/icons/network.svg').default}
              alt="phone"
            />
          </a>
        )}
        {agent.email && (
          <a
            href={`mailto:${agent.email}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.location.href = `mailto:${agent.email}`;
            }}
          >
            <img
              src={require('@assets/svg/icons/sent.svg').default}
              alt="sent"
            />
          </a>
        )}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowRemoveModal(true);
          }}
        >
          <img
            src={require('@assets/svg/icons/delete.svg').default}
            alt="delete"
          />
        </a>
      </div>

      {showRemoveModal && (
        <Modal onClose={() => setShowRemoveModal(false)}>
          <p>Are you sure you want to remove this agent?</p>
          <div className="modal-buttons">
            <button
              className="modal-button modal-button--confirm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
                removeAgent(agent);
              }}
            >
              Yes
            </button>
            <button
              className="modal-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
              }}
            >
              No
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
