import { useState } from 'react';

import { FormInput } from '@components/form_input';
import AgentsMultipleSelect from '@components/selects/agents_select';
import MultipleSelect from '@components/selects/multiple_select';
import { SubmitButton } from '@components/submit_button';
import { removeEmptyFields } from '@utils/helpers';
import { mockTypesOfCustomer } from '@utils/mockData';

export const AddFormCustomers = ({
  agentsAndAgencies,
  createNewCustomer,
}: any) => {
  const [newCustomer, setNewCustomer] = useState<CreateNewCustomerDto>();

  const onCreateCustomersChanged = async (name: string, value: any) => {
    let newValue;

    if (name === 'connections') {
      // console.log(value, 'formValue');
      newValue = Array.isArray(value)
        ? value.map((option) => ({
            id: option.id,
            name: option.name,
            avatar: option.icon,
            type: option.type,
          }))
        : { id: value.id, name: value.name };
    }

    if (name === 'types') {
      newValue = Array.isArray(value)
        ? value.map((option) => ({
            id: option.id,
            name: option.name,
          }))
        : { id: value.id, name: value.name };
    }

    if (name !== 'connections' && name !== 'types') {
      newValue = value;
    }

    const editedCustomer = { ...newCustomer, [name]: newValue };
    setNewCustomer(editedCustomer as any);
  };

  // console.log(newCustomer, 'newCustomer');
  const onSubmit = () => {
    createNewCustomer(removeEmptyFields(newCustomer));
  };
  return (
    <div className="customers-form">
      <FormInput
        type="text"
        label="Full Name"
        name="fullName"
        placeholder="Michael Jane"
        value={newCustomer?.fullName || ''}
        onValueChanged={onCreateCustomersChanged}
      />
      <FormInput
        type="text"
        label="Email"
        name="email"
        placeholder="michael.jane@gmail.com"
        value={newCustomer?.email || ''}
        onValueChanged={onCreateCustomersChanged}
      />
      <MultipleSelect
        options={mockTypesOfCustomer}
        name="types"
        checkBox
        onChange={onCreateCustomersChanged}
        title="Types"
      />
      <AgentsMultipleSelect
        options={agentsAndAgencies}
        name="connections"
        selectOnChange={onCreateCustomersChanged}
        checkBox
        title="Connections"
      />
      <SubmitButton onClick={() => onSubmit()} />
    </div>
  );
};
