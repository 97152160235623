import React, { useState } from 'react';

export interface SelectOption {
  id?: number;
  value?: string;
  label?: string;
  icon?: string;
}

interface SelectProps {
  name: string;
  options?: SelectOption[];
  onRemove?: () => void;
  onChange: (name: string, selectedOption: SelectOption) => void;
  defaultValue?: SelectOption;
  defaultTitle?: string;
}

const Select: React.FC<SelectProps> = ({
  name,
  options,
  onChange,
  onRemove,
  defaultValue,
  defaultTitle,
}) => {
  const [selectedValue, setSelectedValue] = useState(
    defaultValue?.value || options?.[0]?.value,
  );
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option: SelectOption) => {
    setSelectedValue(option.value);
    onChange(name, option);
    setIsOpen(false);
  };

  const selectedOption = options?.find(
    (option) => option.value === selectedValue,
  );

  return (
    <div className={`select-container ${isOpen ? 'open' : ''}`}>
      <div className="select-box" onClick={() => setIsOpen(!isOpen)}>
        <div className="selected-option">
          {defaultTitle && !selectedOption && defaultTitle}
          {selectedOption?.icon && (
            <img src={selectedOption.icon} alt={selectedOption.label} />
          )}
          {selectedOption?.label}
        </div>
        <div style={{ display: 'flex' }}>
          <img
            src={require('@assets/svg/icons/arrow_down_black.svg').default}
            alt="arrow_down_black"
            className={`${isOpen ? 'opened' : ''}`}
          />
          {selectedOption && onRemove && (
            <div style={{ display: 'flex' }}>
              <img
                src={require('@assets/svg/icons/remove.svg').default}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemove && onRemove();
                  setSelectedValue('');
                  setIsOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </div>
      {isOpen && (
        <div className="options-container">
          {options?.map((option) => (
            <div
              key={option.value}
              className={`option ${
                option.value === selectedValue ? 'selected' : ''
              }`}
              onClick={() => handleSelect(option)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex' }}>
                  {option.icon && <img src={option.icon} alt={option.label} />}
                </div>
                <div>{option.label}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Select;
