import { FormAgentsDropDown } from '@components/form_agents_drop_down';
import { FormInput } from '@components/form_input';
import { mockTypesOfCustomer } from '@utils/mockData';

export const CustomerFilters = ({
  searchTerm,
  onSearchChanged,
  handleFilterChange,
}: any) => {
  return (
    <div className="customers-panel-filters">
      <div className="customers-panel-filters__select">
        <FormAgentsDropDown
          title="All"
          icon={require('@assets/svg/icons/agents.svg').default}
          options={mockTypesOfCustomer}
          onRemove={() => handleFilterChange('type', null)}
          onChange={(selectedOption) =>
            handleFilterChange('type', selectedOption)
          }
        />
      </div>
      <div className="customers-panel-filters__input">
        <FormInput
          type="text"
          widget={
            <>
              <img
                src={require('@assets/svg/icons/search.svg').default}
                alt="search"
              />
            </>
          }
          name="search"
          placeholder="Search"
          value={searchTerm?.search || ''}
          onValueChanged={(name: string, value: string) => {
            if (onSearchChanged) {
              onSearchChanged(name, value);
            }
          }}
        />
      </div>
    </div>
  );
};
