import {
  CertificateType,
  CountryId,
  FilterPopupAnchor,
  LocationId,
  PropertyObjectType,
  PropertyType,
  RegionId,
} from '@project/enums';

type PopupAnchors = {
  [key in FilterPopupAnchor]: {
    anchor: Nullable<HTMLElement>;
    isOpened: boolean;
  };
};

export const initialStatePopupAnchors: PopupAnchors = {
  [FilterPopupAnchor.ADVANCED]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.PRICE_RANGE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.BEDROOMS_RANGE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.PROPERTY_TYPE]: {
    anchor: null,
    isOpened: false,
  },
  [FilterPopupAnchor.LOCATION]: {
    anchor: null,
    isOpened: false,
  },
};

export const propertiesTypes: DropDownItem[] = [
  {
    id: PropertyObjectType.VILLA,
    name: 'Villa',
  },
  {
    id: PropertyObjectType.APARTMENT,
    name: 'Apartment',
  },
  {
    id: PropertyObjectType.PENTHOUSE,
    name: 'Penthouse',
  },
  {
    id: PropertyObjectType.HOTEL,
    name: 'Hotel',
  },
  {
    id: PropertyObjectType.TOWNHOUSE,
    name: 'Townhouse',
  },
  {
    id: PropertyObjectType.PLOT,
    name: 'Plot',
  },
  {
    id: PropertyObjectType.COMMERCIAL,
    name: 'Commercial',
  },
];

export const locations: DropDownItem[] = [
  {
    id: LocationId.QUINTA_FORMOSA,
    name: 'Quinta Formosa',
  },
  {
    id: LocationId.VALE_DE_LOBO,
    name: 'Vale de Lobo',
  },
  {
    id: LocationId.ENCOSTA_DO_LOBO,
    name: 'Encosta do Lobo',
  },
  {
    id: LocationId.PINHEIROS_ALTOS,
    name: 'Pinheiros Altos',
  },
  {
    id: LocationId.VALE_VERDE,
    name: 'Vale Verde',
  },
  {
    id: LocationId.VILAS_ALVAS,
    name: 'Vilas Alvas',
  },
  {
    id: LocationId.VARANDAS_DO_LAGO,
    name: 'Varandas do Lago',
  },
  {
    id: LocationId.VALE_DO_GARRAO,
    name: 'Vale do Garrao',
  },
  {
    id: LocationId.QUINTA_DO_MAR,
    name: 'Quinta do Mar',
  },
  {
    id: LocationId.QUINTA_VERDE,
    name: 'Quinta Verde',
  },
  {
    id: LocationId.FONTE_SANTA,
    name: 'Fonte Santa',
  },
  {
    id: LocationId.ALMANCIL,
    name: 'Almancil',
  },
  {
    id: LocationId.VALE_FORMOSO,
    name: 'Vale Formoso',
  },
  {
    id: LocationId.QUARTEIRA,
    name: 'Quarteira',
  },
  {
    id: LocationId.VILAMOURA,
    name: 'Vilamoura',
  },
  {
    id: LocationId.ENCOSTA_DAS_OLIVEIRAS,
    name: 'Encosta das Oliveiras',
  },
  {
    id: LocationId.VILA_SOL,
    name: 'Vila Sol',
  },
  {
    id: LocationId.LOULE,
    name: 'Loule',
  },
  {
    id: LocationId.OLHOS_DE_AGUA,
    name: 'Olhos de Agua',
  },
  {
    id: LocationId.MONCARAPACHO,
    name: 'Moncarapacho',
  },
  {
    id: LocationId.PORCHES,
    name: 'Porches',
  },
  {
    id: LocationId.PARCHAL,
    name: 'Parchal',
  },
  {
    id: LocationId.MONTE_REI_GOLF_RESORT,
    name: 'Monte Rei Golf Resort',
  },
  {
    id: LocationId.ALBUFEIRA,
    name: 'Albufeira',
  },
  {
    id: LocationId.BOLIQUEM,
    name: 'Boliquem',
  },
  {
    id: LocationId.QUINTA_DO_LAGO,
    name: 'Quinta do Lago',
  },
];

export const countries: DropDownItem[] = [
  {
    id: CountryId.PORTUGAL,
    name: 'Portugal',
  },
];

export const regions: DropDownItem[] = [
  {
    id: RegionId.ALGARVE,
    name: 'Algarve',
  },
];

export const priceFilter = [
  { id: 1, name: '> 50,000 €' },
  { id: 2, name: '> 100,000 €' },
  { id: 3, name: '> 150,000 €' },
  { id: 4, name: '> 200,000 €' },
  { id: 5, name: '> 350,000 €' },
  { id: 6, name: '> 500,000 €' },
  { id: 7, name: '> 650,000 €' },
  { id: 8, name: '> 800,000 €' },
  { id: 9, name: '> 900,000 €' },
  { id: 10, name: '> 1,000,000 €' },
  { id: 13, name: '> 1,500,000 €' },
  { id: 16, name: '> 2,000,000 €' },
  { id: 22, name: '> 3,000,000 €' },
];

export const propertyTypes = [
  { id: 1, name: 'Villa' },
  { id: 2, name: 'Apartment' },
  { id: 3, name: 'Penthouse' },
  { id: 4, name: 'Hotel' },
  { id: 5, name: 'Townhouse' },
  { id: 6, name: 'Plot' },
  { id: 7, name: 'Commercial' },
];

export const propertyOwnership = [
  { id: 1, name: 'Company' },
  { id: 2, name: 'Offshore' },
  { id: 3, name: 'Private' },
];

export const certificateTypeOptions = [
  { id: 1, type: CertificateType.A_PLUS_PLUS_PLUS, label: 'A+++' },
  { id: 2, type: CertificateType.A_PLUS_PLUS, label: 'A++' },
  { id: 3, type: CertificateType.A_PLUS, label: 'A+' },
  { id: 4, type: CertificateType.A, label: 'A' },
  { id: 5, type: CertificateType.B, label: 'B' },
  { id: 6, type: CertificateType.C, label: 'C' },
  { id: 7, type: CertificateType.D, label: 'D' },
  { id: 8, type: CertificateType.E, label: 'E' },
  { id: 9, type: CertificateType.F, label: 'F' },
  { id: 10, type: CertificateType.G, label: 'G' },
];

export const propertyOptions: StatusItem[] = [
  { id: 1, type: PropertyType.SOLD, label: 'Sold', disabled: false },
  { id: 2, type: PropertyType.LONG_TERM, label: 'Long term', disabled: true },
  { id: 3, type: PropertyType.SECRET, label: 'Secret', disabled: false },
  { id: 4, type: PropertyType.ARCHIVE, label: 'Archive', disabled: false },
  {
    id: 5,
    type: PropertyType.ON_THE_MARKET,
    label: 'On the market',
    disabled: false,
  },
  { id: 6, type: PropertyType.PRIVATE, label: 'Private', disabled: false },
  {
    id: 7,
    type: PropertyType.ONLY_FOR_CUSTOMERS,
    label: 'Only for customers',
    disabled: true,
  },
  {
    id: 8,
    type: PropertyType.ONLY_FOR_AGENTS,
    label: 'Only for agents',
    disabled: true,
  },
];

export const subTitles = [
  {
    id: 1,
    name: 'New Listing',
    disabled: false,
  },
  {
    id: 2,
    name: 'Under Offer',
    disabled: false,
  },
  {
    id: 3,
    name: 'Reduced Price',
    disabled: false,
  },
  {
    id: 4,
    name: 'Great Investment',
    disabled: false,
  },
  {
    id: 5,
    name: 'Old',
    disabled: false,
  },
];

export const mockTypesOfCustomer = [
  {
    id: 1,
    value: 'buyer',
    name: 'Buyer',
    icon: require('@assets/svg/icons/profile.svg').default,
  },
  {
    id: 2,
    value: 'vendor',
    name: 'Vendor',
    icon: require('@assets/svg/icons/profile.svg').default,
  },
];

export const mockPaymentMethodsOfCustomer = [
  {
    id: 1,
    value: 'cash',
    name: 'Cash',
    icon: require('@assets/svg/icons/cashOrMortgage.svg').default,
  },
  {
    id: 2,
    value: 'mortgage',
    name: 'Mortgage',
    icon: require('@assets/svg/icons/cashOrMortgage.svg').default,
  },
];
