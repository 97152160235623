import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { MessageType } from '@project/enums';
import { AppActions } from '@store/items/app';
import { CustomersAction } from '@store/items/customers';
import { filterCustomer, updateCustomer } from '@utils/helpers';

const customersSelector = (state: RootState): Customer[] => {
  return state.customers.customers;
};

export function* getAllCustomers() {
  yield put(AppActions.lockUI());

  try {
    const result: { customers: Customer[] } = yield call(
      ApiRequests.getAllCustomersRequest,
    );
    yield put(CustomersAction.setCustomers(result.customers));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* createNewCustomer(
  action: DispatchWithPayload<CreateNewCustomerDto>,
): SagaIterator {
  yield put(AppActions.lockUI());

  try {
    const newCustomer: { customer: Customer } | ApiMessage = yield call(
      ApiRequests.createNewCustomerRequest,
      action.payload,
    );
    if ((newCustomer as ApiMessage).message) {
      throw (newCustomer as ApiMessage).message;
    }

    const customers = yield select(customersSelector);
    const newCustomers = [
      (newCustomer as { customer: Customer }).customer,
      ...customers,
    ];
    yield put(CustomersAction.setCustomers(newCustomers));
    showToast({
      type: MessageType.SUCCESS,
      message: 'agents was created',
    });
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: 'An error occurred',
    });

    if (e === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* removeCustomerById(action: DispatchWithPayload<Customer>) {
  yield put(AppActions.lockUI());

  try {
    const result: Customer | ApiMessage = yield call(
      ApiRequests.deleteCustomerById,
      action.payload,
    );
    const { id } = action.payload;
    const customers: Customer[] = yield select(customersSelector);
    const newCustomers = filterCustomer(customers, { id });
    yield put(CustomersAction.setCustomers(newCustomers));

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateCustomerAvatarById(
  action: DispatchWithPayload<ExactCustomerAvatar>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: { customer: Customer } = yield call(
      ApiRequests.addCustomerAvatar,
      action.payload,
    );
    const customers: Customer[] = yield select(customersSelector);
    const newCustomers = updateCustomer(customers, result.customer);
    yield put(CustomersAction.addCustomerAvatarSuccess(newCustomers));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeCustomerAvatar(action: DispatchWithPayload<Customer>) {
  yield put(AppActions.lockUI());
  try {
    const result: Agency = yield call(
      ApiRequests.deleteCustomerAvatarById,
      action.payload,
    );
  } catch (error) {
    showToast({
      type:
        error === 'CUSTOMER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateCustomerById(action: DispatchWithPayload<Customer>) {
  yield put(AppActions.lockUI());

  try {
    const result: { customer: Customer } = yield call(
      ApiRequests.updateCustomerFormRequest,
      action.payload,
    );

    const customers: Customer[] = yield select(customersSelector);
    const newCustomers = updateCustomer(customers, {
      id: result.customer.id,
    });
    yield put(CustomersAction.updateCustomerSuccess(newCustomers));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* AddCustomersDocumentsById(
  action: DispatchWithPayload<Customer>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: { customer: Customer } | ApiMessage = yield call(
      ApiRequests.addCustomerDocumentsRequest,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    const customers: Customer[] = yield select(customersSelector);
    const newCustomers = updateCustomer(
      customers,
      (result as { customer: Customer }).customer,
    );

    yield put(CustomersAction.addCustomerDocumentsSuccess(newCustomers));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeCustomerDocument(action: DispatchWithPayload<Customer>) {
  yield put(AppActions.lockUI());

  try {
    const result: Customer | ApiMessage = yield call(
      ApiRequests.deleteCustomerDocumentById,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(CustomersAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}
