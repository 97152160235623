import { ReduxActionType } from '@project/enums';
import {
  removeCustomerAvatar,
  removeCustomerDocument,
  updateCustomer,
} from '@utils/helpers';

const initialState: CustomersState = {
  customers: [],
  isLoading: false,
};

export function customersReducer(
  state: CustomersState = initialState,
  action: DispatchWithPayload<Customer[]>,
): CustomersState {
  switch (action.type) {
    default:
      return state;

    case ReduxActionType.CUSTOMERS_REQUEST_GET_ALL:
      return {
        ...state,
        isLoading: true,
      };
    case ReduxActionType.CUSTOMERS_SET_DATA:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CUSTOMERS_CREATE_NEW_CUSTOMER_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CUSTOMERS_REQUEST_ADD_AVATAR:
      return {
        ...state,
        isLoading: true,
      };
    case ReduxActionType.CUSTOMERS_REQUEST_ADD_AVATAR_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CUSTOMERS_REQUEST_REMOVE_AVATAR:
      return {
        ...state,
        customers: removeCustomerAvatar(state.customers, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
        }),
      };
    case ReduxActionType.CUSTOMER_REQUEST_UPDATE:
      return {
        ...state,
        /*@ts-ignore*/
        customers: updateCustomer(state.customers, action.payload),
        isLoading: true,
      };

    case ReduxActionType.CUSTOMER_SUCCESS_UPDATE:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CUSTOMER_REQUEST_ADD_DOCUMENTS:
      return {
        ...state,
        // agents: action.payload,
        isLoading: true,
      };
    case ReduxActionType.CUSTOMER_REQUEST_ADD_DOCUMENTS_SUCCESS:
      return {
        ...state,
        customers: action.payload,
        isLoading: false,
      };
    case ReduxActionType.CUSTOMER_REQUEST_REMOVE_DOCUMENT:
      return {
        ...state,
        customers: removeCustomerDocument(state.customers, {
          /*@ts-ignore*/
          id: action.payload.id,
          /*@ts-ignore*/
          type: action.payload.type,
          /*@ts-ignore*/
          url: action.payload.url,
        }),
      };
  }
}

export class CustomersAction {
  public static getCustomers(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_GET_ALL,
    };
  }
  public static setCustomers(
    payload: Customer[],
  ): DispatchWithPayload<Customer[]> {
    return {
      type: ReduxActionType.CUSTOMERS_SET_DATA,
      payload,
    };
  }
  public static CreateNewCustomerRequest(
    payload: CreateNewCustomerDto,
  ): DispatchWithPayload<CreateNewCustomerDto> {
    return {
      type: ReduxActionType.CUSTOMERS_CREATE_REQUEST_NEW_CUSTOMER,
      payload,
    };
  }
  public static createNewCustomerSuccess(
    payload: CreateNewCustomerDto,
  ): DispatchWithPayload<CreateNewCustomerDto> {
    return {
      type: ReduxActionType.CUSTOMERS_CREATE_NEW_CUSTOMER_SUCCESS,
      payload,
    };
  }
  public static removeCustomer(payload: Agency): DispatchWithPayload<Agency> {
    return {
      type: ReduxActionType.REQUEST_DELETE_CUSTOMER,
      payload,
    };
  }
  public static addCustomerAvatar(
    payload: ExactCustomerAvatar,
  ): DispatchWithPayload<ExactCustomerAvatar> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_ADD_AVATAR,
      payload,
    };
  }
  public static addCustomerAvatarSuccess(
    payload: Customer[],
  ): DispatchWithPayload<Customer[]> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_ADD_AVATAR_SUCCESS,
      payload,
    };
  }

  public static removeCustomerAvatar(
    payload: Customer,
  ): DispatchWithPayload<Customer> {
    return {
      type: ReduxActionType.CUSTOMERS_REQUEST_REMOVE_AVATAR,
      payload,
    };
  }

  public static updateCustomer(
    payload: Customer,
  ): DispatchWithPayload<Customer> {
    return {
      type: ReduxActionType.CUSTOMER_REQUEST_UPDATE,
      payload,
    };
  }
  public static updateCustomerSuccess(
    payload: Customer[],
  ): DispatchWithPayload<Customer[]> {
    return {
      type: ReduxActionType.CUSTOMER_SUCCESS_UPDATE,
      payload,
    };
  }

  public static addCustomerDocuments(
    payload: Customer,
  ): DispatchWithPayload<Customer> {
    return {
      type: ReduxActionType.CUSTOMER_REQUEST_ADD_DOCUMENTS,
      payload,
    };
  }
  public static addCustomerDocumentsSuccess(
    payload: Customer[],
  ): DispatchWithPayload<Customer[]> {
    return {
      type: ReduxActionType.CUSTOMER_REQUEST_ADD_DOCUMENTS_SUCCESS,
      payload,
    };
  }

  public static removeCustomerDocument(
    payload: Customer,
  ): DispatchWithPayload<Customer> {
    return {
      type: ReduxActionType.CUSTOMER_REQUEST_REMOVE_DOCUMENT,
      payload,
    };
  }

  public static resetData(): DispatchWithoutPayload {
    return {
      type: ReduxActionType.MANAGERS_RESET_DATA,
    };
  }
}
