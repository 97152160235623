import { useState } from 'react';

import { Docs } from '@components/sections/docs';
import { PreferencesContainer } from '@components/sections/preferences_container';

const sections: Array<keyof AccordionState> = ['docs', 'preferences'];

type AgencyContentProps = {
  agency: Agency;
  isOpened: AccordionState;
  handleToggleSection: (section: keyof AccordionState) => void;
  updateAgency: (payload: Agency) => DispatchWithPayload<Agency>;
  addAgencyDocument: (payload: Agency) => DispatchWithPayload<Agency>;
  removeAgencyDocument: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
};

export function AgencyContent({
  isOpened,
  handleToggleSection,
  agency,
  updateAgency,
  addAgencyDocument,
  removeAgencyDocument,
}: AgencyContentProps): JSX.Element {
  const [addNewPreference, setAddNewPreference] = useState(false);

  const handleAddNewPreference = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAddNewPreference(true);
  };

  const resetAddNewPreferenceFlag = () => {
    setAddNewPreference(false);
  };

  return (
    <>
      {sections.map((section) => (
        <div
          key={section}
          className={`section ${isOpened[section] ? 'open' : ''}`}
        >
          <div
            className="section-header"
            onClick={() => handleToggleSection(section)}
          >
            <img
              src={require('@assets/svg/icons/arrow-down.svg').default}
              alt="arrow-down"
              className={`arrow-icon ${isOpened[section] ? 'opened' : ''}`}
            />
            <span className="section-title">
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </span>
            {isOpened[section] && section === 'preferences' && (
              <button className="add-button" onClick={handleAddNewPreference}>
                <img src={require('@assets/svg/icons/plus.svg').default} />
                ADD
              </button>
            )}
          </div>
          <div className={`section-content ${isOpened[section] ? 'open' : ''}`}>
            {renderSectionContent(
              section,
              agency,
              addAgencyDocument,
              removeAgencyDocument,
              updateAgency,
              addNewPreference,
              resetAddNewPreferenceFlag,
            )}
          </div>
        </div>
      ))}
    </>
  );
}

const renderSectionContent = (
  section: string,
  agency: Agency,
  addAgencyDocument: (payload: Agency) => DispatchWithPayload<Agency>,
  removeAgencyDocument: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>,
  updateAgency: (payload: Agency) => DispatchWithPayload<Agency>,
  addNewPreference: boolean,
  resetAddNewPreferenceFlag: () => void,
) => {
  switch (section) {
    case 'docs':
      return (
        <Docs
          title="Contract Agreement"
          user={agency}
          addUserDocument={addAgencyDocument}
          removeDocument={removeAgencyDocument}
        />
      );
    case 'preferences':
      return (
        <PreferencesContainer
          /*@ts-ignore*/
          user={agency}
          onUpdate={updateAgency}
          addNewPreference={addNewPreference}
          resetAddNewPreferenceFlag={resetAddNewPreferenceFlag}
        />
      );
    default:
      return null;
  }
};
