import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FileUploader } from '@components/file_uploader/file_uploader';
import { FormInput } from '@components/form_input';
import { PhotoDataButton } from '@components/photo_data_button';
import MultipleSelect from '@components/selects/multiple_select';
import { adaptCustomerToSend } from '@utils/helpers';
import {
  mockPaymentMethodsOfCustomer,
  mockTypesOfCustomer,
} from '@utils/mockData';

export const CustomersForm = ({
  customer,
  addCustomerAvatar,
  removeCustomerAvatar,
  updateCustomer,
}: any) => {
  const timeoutRef = useRef<number | null>(null);
  const [editCustomer, setEditCustomer] = useState<Customer>({
    id: customer?.id,
    fullName: customer?.fullName || undefined,
    email: customer?.email || undefined,
    birthday: customer?.birthday || undefined,
    nationality: customer?.nationality || undefined,
    phoneNumber: customer?.phoneNumber || undefined,
    whatsAppNumber: customer?.whatsAppNumber || undefined,
    telegramUserName: customer?.telegramUserName || undefined,
    types: customer?.types || undefined,
    paymentMethods: customer?.paymentMethods || undefined,
  });
  console.log(editCustomer, 'editCustomer');
  const handleFileUpload = (file: File | File[]) => {
    addCustomerAvatar({ id: customer.id, avatar: file });
  };

  const onEditCustomersChanged = async (name: string, value: any) => {
    let newValue = value;

    if (name === 'types' || name === 'paymentMethods') {
      newValue = Array.isArray(value)
        ? value.map((option) => ({
            id: option.id,
            name: option.name,
          }))
        : { id: value.id, name: value.name };
    } else {
      newValue = value;
    }

    const editedCustomer = { ...editCustomer, [name]: newValue };
    setEditCustomer(editedCustomer);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    /*@ts-ignore*/
    timeoutRef.current = setTimeout(() => {
      updateCustomer(adaptCustomerToSend(editedCustomer));
    }, 2000);
  };
  const remappedDefaultValue = (option?: { id: number; name: string }) => {
    return {
      id: option?.id,
      value: option?.name?.toLowerCase().replace(/\s+/g, '-') || '',
      label: option?.name || '',
      icon: require('@assets/svg/icons/cashOrMortgage.svg').default,
    };
  };

  return (
    <form className="form-container">
      {/*fileUploader*/}
      <div>
        {!customer.avatar ? (
          <FileUploader
            className="file-uploader"
            onFileUpload={handleFileUpload}
            children={
              <PhotoDataButton
                label={'UPLOAD AVATAR'}
                icon={require('@assets/svg/icons/upload.svg').default}
              />
            }
          />
        ) : (
          <PhotoDataButton
            label="REMOVE AVATAR"
            icon={require('@assets/svg/icons/remove.svg').default}
            onClick={() => removeCustomerAvatar(customer)}
          />
        )}
      </div>
      {/*fullName*/}
      <div>
        <FormInput
          type="text"
          label="Full Name"
          name="fullName"
          placeholder={editCustomer?.fullName || ''}
          value={editCustomer.fullName || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      {/*Email*/}
      <div>
        <FormInput
          type="text"
          label="Email"
          name="email"
          placeholder={editCustomer?.email || ''}
          value={editCustomer.email || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      {/*birthday*/}
      <div className="date-picker">
        <DatePicker
          className="form-input"
          showIcon
          icon={
            <img
              src={require('@assets/svg/icons/birthday.svg').default}
              alt="birthday"
            />
          }
          /*@ts-ignore*/
          selected={editCustomer.birthday || undefined}
          onChange={(date) => onEditCustomersChanged('birthday', date)}
        />
      </div>
      {/*Tel*/}
      <div>
        <FormInput
          type="number"
          label="Tel"
          name="phoneNumber"
          placeholder={editCustomer?.phoneNumber || ''}
          value={editCustomer.phoneNumber || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      <div>
        {/*Nationality*/}
        <FormInput
          type="text"
          label="Nationality"
          name="nationality"
          placeholder={editCustomer?.nationality || ''}
          value={editCustomer.nationality || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      <div>
        {/*whatsapp*/}
        <FormInput
          type="text"
          widget={
            <>
              <img
                src={require('@assets/svg/icons/whatsapp.svg').default}
                alt="whatsapp"
              />
            </>
          }
          icon={require('@assets/svg/icons/pin.svg').default}
          name="whatsAppNumber"
          placeholder={editCustomer?.whatsAppNumber || ''}
          value={editCustomer.whatsAppNumber || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      {/*telegram*/}
      <div>
        <FormInput
          type="text"
          widget={
            <>
              <img
                src={require('@assets/svg/icons/telegram.svg').default}
                alt="telegram"
              />
            </>
          }
          name="telegramUserName"
          placeholder={editCustomer?.telegramUserName || ''}
          value={editCustomer.telegramUserName || ''}
          onValueChanged={onEditCustomersChanged}
        />
      </div>
      {/*Buyer*/}
      <div>
        <MultipleSelect
          options={mockTypesOfCustomer}
          name="types"
          title="Types"
          onChange={onEditCustomersChanged}
          checkBox={true}
          defaultValue={editCustomer.types}
        />
      </div>
      {/*cash or mortgage*/}
      <div>
        <MultipleSelect
          options={mockPaymentMethodsOfCustomer}
          name="paymentMethods"
          title="Payment Methods"
          onChange={onEditCustomersChanged}
          checkBox={true}
          defaultValue={editCustomer.paymentMethods}
        />
      </div>
    </form>
  );
};
