import { Checkbox } from '@components/checkbox';
import { FormAgentsDropDown } from '@components/form_agents_drop_down';
import { FormInput } from '@components/form_input';
import { FormName } from '@project/enums';
import { locations, propertyTypes } from '@utils/mockData';

export function Preferences({
  index,
  preference,
  onPreferencesChanged,
}: PreferencesProps) {
  return (
    <div className="agents-preferences-container">
      <div className="agents-form-container__select">
        <FormAgentsDropDown
          title="Location"
          defaultValue={preference?.location || undefined}
          options={locations}
          onChange={(selectedOption) =>
            onPreferencesChanged(index, 'location', selectedOption.id)
          }
        />
      </div>
      <div className="agents-form-container__select">
        <FormAgentsDropDown
          title="Type"
          defaultValue={preference.type}
          options={propertyTypes}
          onChange={(selectedOption) =>
            onPreferencesChanged(index, 'type', selectedOption.id)
          }
        />
      </div>
      <div className="price">
        <div>
          <FormInput
            type="number"
            name="from"
            value={preference.from || ''}
            onValueChanged={(name: FormName, value: string) =>
              onPreferencesChanged(index, name, value)
            }
            placeholder="20000$"
          />
        </div>
        <span>&mdash;</span>
        <div>
          <FormInput
            type="number"
            name="to"
            value={preference.to || ''}
            onValueChanged={(name: FormName, value: string) =>
              onPreferencesChanged(index, name, value)
            }
            placeholder="50000$"
          />
        </div>
      </div>
      <div className="preferences-checkbox">
        <Checkbox
          onChange={(flag: boolean) =>
            onPreferencesChanged(index, 'exactly', flag)
          }
          checked={preference.exactly}
        />
        <label>Exactly</label>
      </div>
    </div>
  );
}
