import { useState } from 'react';

import { Docs } from '@components/sections/docs';
import { PreferencesContainer } from '@components/sections/preferences_container';

const sections: Array<keyof AccordionState> = ['docs', 'preferences'];

type AgentContentProps = {
  agent: Agent;
  isOpened: AccordionState;
  handleToggleSection: (section: keyof AccordionState) => void;
  addAgentsDocument: (payload: Agent) => DispatchWithPayload<Agent>;
  removeAgentDocument: (
    payload: ExactPartner,
  ) => DispatchWithPayload<ExactPartner>;
  updateAgent: (payload: Agent) => DispatchWithPayload<Agent>;
};

export function AgentContent({
  isOpened,
  handleToggleSection,
  addAgentsDocument,
  agent,
  updateAgent,
  removeAgentDocument,
}: AgentContentProps): JSX.Element {
  const [addNewPreference, setAddNewPreference] = useState(false);

  const handleAddNewPreference = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAddNewPreference(true);
  };

  const resetAddNewPreferenceFlag = () => {
    setAddNewPreference(false);
  };

  return (
    <>
      {sections.map((section) => (
        <div
          key={section}
          className={`section ${isOpened[section] ? 'open' : ''}`}
        >
          <div
            className="section-header"
            onClick={() => handleToggleSection(section)}
          >
            <img
              src={require('@assets/svg/icons/arrow-down.svg').default}
              alt="arrow-down"
              className={`arrow-icon ${isOpened[section] ? 'opened' : ''}`}
            />
            <span className="section-title">
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </span>
            {isOpened[section] && section === 'preferences' && (
              <button className="add-button" onClick={handleAddNewPreference}>
                <img src={require('@assets/svg/icons/plus.svg').default} />
                ADD
              </button>
            )}
          </div>
          <div className={`section-content ${isOpened[section] ? 'open' : ''}`}>
            {renderSectionContent(
              section,
              addAgentsDocument,
              agent,
              updateAgent,
              removeAgentDocument,
              addNewPreference,
              resetAddNewPreferenceFlag,
            )}
          </div>
        </div>
      ))}
    </>
  );
}

const renderSectionContent = (
  section: string,
  addAgentsDocument: (payload: Agent) => DispatchWithPayload<Agent>,
  agent: Agent,
  updateAgent: (payload: Agent) => DispatchWithPayload<Agent>,
  removeAgentDocument: (
    payload: ExactPartner,
  ) => DispatchWithPayload<ExactPartner>,
  addNewPreference: boolean,
  resetAddNewPreferenceFlag: () => void,
) => {
  switch (section) {
    case 'docs':
      return (
        <Docs
          title="Contract Agreement"
          user={agent}
          addUserDocument={addAgentsDocument}
          removeDocument={removeAgentDocument}
        />
      );
    case 'preferences':
      return (
        <PreferencesContainer
          /*@ts-ignore*/
          user={agent}
          onUpdate={updateAgent}
          addNewPreference={addNewPreference}
          resetAddNewPreferenceFlag={resetAddNewPreferenceFlag}
        />
      );
    default:
      return null;
  }
};
