import { useState } from 'react';

import Modal from '@components/modal';

export const CustomerSocial = ({
  customer,
  removeCustomer,
}: {
  customer: Customer;
  removeCustomer: (customer: Customer) => void;
}) => {
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  return (
    <div className="data-item__person-social-data">
      {/* Telegram */}
      {customer.telegramUserName && (
        <a
          href={`https://t.me/${customer.telegramUserName}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(`https://t.me/${customer.telegramUserName}`, '_blank');
          }}
        >
          <img
            src={require('@assets/svg/icons/telegram.svg').default}
            alt="telegram"
          />
        </a>
      )}

      {/* WhatsApp */}
      {customer.whatsAppNumber && (
        <a
          href={`https://wa.me/${customer.whatsAppNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.open(`https://wa.me/${customer.whatsAppNumber}`, '_blank');
          }}
        >
          <img
            src={require('@assets/svg/icons/whatsapp.svg').default}
            alt="whatsapp"
          />
        </a>
      )}

      {/* Phone */}
      {customer.phoneNumber && (
        <a
          href={`tel:${customer.phoneNumber}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = `tel:${customer.phoneNumber}`;
          }}
        >
          <img
            src={require('@assets/svg/icons/network.svg').default}
            alt="phone"
          />
        </a>
      )}

      {/* Email */}
      {customer.email && (
        <a
          href={`mailto:${customer.email}`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = `mailto:${customer.email}`;
          }}
        >
          <img
            src={require('@assets/svg/icons/sent.svg').default}
            alt="email"
          />
        </a>
      )}

      {/* Delete */}
      <a
        href="#"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowRemoveModal(true);
        }}
      >
        <img
          src={require('@assets/svg/icons/delete.svg').default}
          alt="delete"
        />
      </a>
      {/* )} */}
      {showRemoveModal && (
        <Modal onClose={() => setShowRemoveModal(false)}>
          <p>Are you sure you want to remove this customer?</p>
          <div className="modal-buttons">
            <button
              className="modal-button modal-button--confirm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
                removeCustomer(customer);
              }}
            >
              Yes
            </button>
            <button
              className="modal-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
              }}
            >
              No
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};
