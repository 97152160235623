export const enum RuntimeMode {
  DEV = 'development',
  PROD = 'production',
}

export const enum LocalStorageKey {
  USER_LANG = 'userLang',
  CREDENTIALS = 'credentials',
}

export const enum FormName {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const enum RouterAccessStatus {
  UNAUTHORIZED = 'UNAUTHORIZED',
  ROOT = 'ROOT',
}

export const enum AppRoutes {
  ANY = '*',
  ROOT = '/',
  ANALYTICS = '/analytics',
  PARTNERS = '/partners',
  AGENTS = '/agents',
  REQUESTS = '/requests',
  PROPERTIES = '/properties',
  ADD_NEW_PROPERTY = '/add-new-property',
  EDIT_PROPERTY = '/edit-property',
  CUSTOMERS = '/customers',
}

export const enum FilterPopupAnchor {
  ADVANCED = 'ADVANCED',
  PRICE_RANGE = 'PRICE_RANGE',
  BEDROOMS_RANGE = 'BEDROOMS_RANGE',
  PROPERTY_TYPE = 'PROPERTY_TYPE',
  LOCATION = 'LOCATION',
}

export const enum PropertyType {
  VILLA = 1,
  APARTMENT = 2,
  PENTHOUSE = 3,
  HOTEL = 4,
  TOWNHOUSE = 5,
  PLOT = 6,
  COMMERCIAL = 7,
}

export const enum PropertyStatus {
  SOLD = 1,
  LONG_TERM = 2,
  SECRET = 3,
  ARCHIVE = 4,
  ON_THE_MARKET = 5,
  PRIVATE = 6,
  ONLY_FOR_CUSTOMERS = 7,
  ONLY_FOR_AGENTS = 8,
}

export const enum SubTitles {
  NEW_LISTING = 1,
  UNDER_OFFER = 2,
  REDUCED_PRICE = 3,
  GREAT_INVESTMENT = 4,
}

export const enum PropertyOwnership {
  COMPANY = 1,
  OFFSHORE = 2,
  PRIVATE = 3,
}

export const enum CountryId {
  PORTUGAL = 1,
}

export const enum RegionId {
  ALGARVE = 1,
}

export const enum LocationId {
  QUINTA_FORMOSA = 1,
  VALE_DE_LOBO = 2,
  ENCOSTA_DO_LOBO = 3,
  PINHEIROS_ALTOS = 4,
  VALE_VERDE = 5,
  VILAS_ALVAS = 6,
  VARANDAS_DO_LAGO = 7,
  VALE_DO_GARRAO = 8,
  QUINTA_DO_MAR = 9,
  QUINTA_VERDE = 10,
  FONTE_SANTA = 11,
  ALMANCIL = 12,
  VALE_FORMOSO = 13,
  QUARTEIRA = 14,
  VILAMOURA = 15,
  ENCOSTA_DAS_OLIVEIRAS = 16,
  VILA_SOL = 17,
  LOULE = 18,
  OLHOS_DE_AGUA = 19,
  MONCARAPACHO = 20,
  PORCHES = 21,
  PARCHAL = 22,
  MONTE_REI_GOLF_RESORT = 23,
  ALBUFEIRA = 24,
  BOLIQUEM = 25,
  QUINTA_DO_LAGO = 26,
}

export const enum PropertyObjectType {
  VILLA = 1,
  APARTMENT = 2,
  PENTHOUSE = 3,
  HOTEL = 4,
  TOWNHOUSE = 5,
  PLOT = 6,
  COMMERCIAL = 7,
}

export const enum PropertySolarOrientation {
  NORTH = 1,
  EAST = 2,
  SOUTH = 3,
  WEST = 4,
}

export const enum AppError {
  UNABLE_TO_CREATE_NEW_PARTNER = 'UNABLE_TO_CREATE_NEW_PARTNER',
}

export const enum AppLoader {
  MAIN_LOADING = 'MAIN_LOADING',
}

export const enum ReduxActionType {
  APP_LOCK_UI = 'APP_LOCK_UI',
  APP_UNLOCK_UI = 'APP_UNLOCK_UI',
  APP_SET_ERROR = 'APP_SET_ERROR',
  APP_RESET_ERROR = 'APP_RESET_ERROR',
  APP_SET_LOADER = 'APP_SET_LOADER',
  APP_RESET_LOADER = 'APP_RESET_LOADER',

  MANAGERS_REQUEST_SIGN_IN = 'MANAGERS_REQUEST_SIGN_IN',
  MANAGERS_REQUEST_LOGOUT = 'MANAGERS_REQUEST_LOGOUT',
  MANAGERS_SET_DATA = 'MANAGERS_SET_DATA',
  MANAGERS_RESET_DATA = 'MANAGERS_RESET_DATA',

  PARTNERS_REQUEST_CREATE_NEW_PARTNER = 'PARTNERS_REQUEST_CREATE_NEW_PARTNER',
  PARTNERS_SET_NEW_PARTNER = 'PARTNERS_SET_NEW_PARTNER',
  PARTNERS_REQUEST_DELETE_PARTNER = 'PARTNERS_REQUEST_DELETE_PARTNER',
  PARTNERS_DELETE_PARTNER_SUCCESS = 'PARTNERS_DELETE_PARTNER_SUCCESS',
  PARTNERS_SET_DATA = 'PARTNERS_SET_DATA',
  PARTNERS_REQUEST_UPDATE_PARTNER = 'PARTNERS_REQUEST_UPDATE_PARTNER',
  CREATE_NEW_PARTNER_SUCCESS = 'CREATE_NEW_PARTNER_SUCCESS',
  PARTNER_SUCCESS_UPDATE = 'PARTNER_SUCCESS_UPDATE',
  PARTNER_REQUEST_REMOVE_AVATAR = 'PARTNER_REMOVE_AVATAR',
  PARTNER_REMOVE_AVATAR_SUCCESS = 'PARTNER_REMOVE_AVATAR_SUCCESS',

  // eslint-disable-next-line max-len
  PROPERTIES_REQUEST_GET_ALL_PROPERTIES = 'PROPERTIES_REQUEST_GET_ALL_PROPERTIES',
  PROPERTIES_SET_DATA = 'PROPERTIES_SET_DATA',
  PROPERTIES_UPDATE_PROPERTY = 'PROPERTIES_UPDATE_PROPERTY',
  PROPERTIES_UPDATE_PROPERTY_SUCCESS = 'PROPERTIES_UPDATE_PROPERTY_SUCCESS',
  PROPERTIES_REQUEST_DELETE_PROPERTY = 'PROPERTIES_REQUEST_DELETE_PROPERTY',

  PROPERTY_REQUEST_GET_PROPERTY = 'PROPERTIES_REQUEST_GET_PROPERTY',
  PROPERTY_SET_DATA = 'PROPERTY_SET_DATA',
  PROPERTY_RESET_DATA = 'PROPERTY_RESET_DATA',
  PROPERTY_REQUEST_UPDATE = 'PROPERTY_REQUEST_UPDATE',
  PROPERTY_REQUEST_UPDATE_SUCCESS = 'PROPERTY_REQUEST_UPDATE_SUCCESS',
  PROPERTY_REQUEST_ADD_PHOTO = 'PROPERTY_REQUEST_ADD_PHOTO',
  PROPERTY_REQUEST_ADD_PHOTO_SUCCESS = 'PROPERTY_REQUEST_ADD_PHOTO_SUCCESS',
  PROPERTY_REQUEST_CREATE_NEW_PROPERTY = 'PROPERTY_REQUEST_CREATE_NEW_PROPERTY',
  PROPERTY_REQUEST_REMOVE = 'PROPERTY_REQUEST_REMOVE',
  PROPERTY_REQUEST_REMOVE_SUCCESS = 'PROPERTY_REQUEST_REMOVE_SUCCESS',
  // eslint-disable-next-line max-len
  PROPERTY_REQUEST_CREATE_NEW_PROPERTY_SUCCESS = 'PROPERTY_REQUEST_CREATE_NEW_PROPERTY_SUCCESS',

  PARTNERS_REQUEST_GET_ALL_PARTNERS = 'PARTNERS_REQUEST_GET_ALL_PARTNERS',

  //Agent - Agencies
  // eslint-disable-next-line max-len
  AGENTS_REQUEST_GET_ALL_AGENTS_AND_AGENCIES = 'AGENTS_REQUEST_GET_ALL_AGENTS_AND_AGENCIES',

  //Agents
  AGENTS_AND_AGENCIES_SET_DATA = 'AGENTS_AND_AGENCIES_SET_DATA',
  CREATE_NEW_AGENT_SUCCESS = 'CREATE_NEW_AGENT_SUCCESS',
  AGENTS_REQUEST_CREATE_NEW_AGENT = 'AGENTS_REQUEST_CREATE_NEW_AGENT',
  REQUEST_DELETE_AGENT = 'REQUEST_DELETE_AGENT',
  REQUEST_DELETE_AGENT_SUCCESS = 'REQUEST_DELETE_AGENT_SUCCESS',
  AGENTS_REQUEST_ADD_AVATAR = 'AGENTS_REQUEST_ADD_AVATAR',
  AGENTS_REQUEST_ADD_AVATAR_SUCCESS = 'AGENTS_REQUEST_ADD_AVATAR_SUCCESS',
  AGENT_REQUEST_REMOVE_AVATAR = 'AGENT_REQUEST_REMOVE_AVATAR',
  AGENT_REQUEST_UPDATE = 'AGENT_REQUEST_UPDATE',
  AGENT_SUCCESS_UPDATE = 'AGENT_SUCCESS_UPDATE',
  AGENTS_REQUEST_ADD_DOCUMENTS = 'AGENTS_REQUEST_ADD_DOCUMENTS',
  AGENTS_REQUEST_ADD_DOCUMENTS_SUCCESS = 'AGENTS_REQUEST_ADD_DOCUMENTS_SUCCESS',
  AGENT_REQUEST_REMOVE_DOCUMENT = 'AGENT_REQUEST_REMOVE_DOCUMENT',

  //AGENCY
  REQUEST_CREATE_NEW_AGENCY = 'REQUEST_CREATE_NEW_AGENCY',
  CREATE_NEW_AGENCY_SUCCESS = 'CREATE_NEW_AGENCY_SUCCESS',
  AGENCY_REQUEST_ADD_AVATAR = 'AGENCY_REQUEST_ADD_AVATAR',
  AGENCY_REQUEST_ADD_AVATAR_SUCCESS = 'AGENCY_REQUEST_ADD_AVATAR_SUCCESS',
  AGENCY_REQUEST_REMOVE_AVATAR = 'AGENCY_REQUEST_REMOVE_AVATAR',
  REQUEST_DELETE_AGENCY = 'REQUEST_DELETE_AGENCY',
  REQUEST_DELETE_AGENCY_SUCCESS = 'REQUEST_DELETE_AGENCY_SUCCESS',
  AGENCY_REQUEST_UPDATE = 'AGENCY_REQUEST_UPDATE',
  AGENCY_SUCCESS_UPDATE = 'AGENCY_SUCCESS_UPDATE',
  AGENCY_REQUEST_ADD_DOCUMENTS = 'AGENCY_REQUEST_ADD_DOCUMENTS',
  AGENCY_REQUEST_ADD_DOCUMENTS_SUCCESS = 'AGENCY_REQUEST_ADD_DOCUMENTS_SUCCESS',
  AGENCY_REQUEST_REMOVE_DOCUMENT = 'AGENCY_REQUEST_REMOVE_DOCUMENT',

  //CUSTOMERS
  CUSTOMERS_REQUEST_GET_ALL = 'CUSTOMERS_REQUEST_GET_ALL',
  CUSTOMERS_SET_DATA = 'CUSTOMERS_SET_DATA',
  // eslint-disable-next-line max-len
  CUSTOMERS_CREATE_REQUEST_NEW_CUSTOMER = 'CUSTOMERS_CREATE_REQUEST_NEW_CUSTOMER',
  // eslint-disable-next-line max-len
  CUSTOMERS_CREATE_NEW_CUSTOMER_SUCCESS = 'CUSTOMERS_CREATE_NEW_CUSTOMER_SUCCESS',
  REQUEST_DELETE_CUSTOMER = 'REQUEST_DELETE_CUSTOMER',
  CUSTOMERS_REQUEST_ADD_AVATAR = 'CUSTOMERS_REQUEST_ADD_AVATAR',
  CUSTOMERS_REQUEST_ADD_AVATAR_SUCCESS = 'CUSTOMERS_REQUEST_ADD_AVATAR_SUCCESS',
  CUSTOMERS_REQUEST_REMOVE_AVATAR = 'CUSTOMERS_REQUEST_REMOVE_AVATAR',
  CUSTOMER_REQUEST_UPDATE = 'CUSTOMER_REQUEST_UPDATE',
  CUSTOMER_SUCCESS_UPDATE = 'CUSTOMER_SUCCESS_UPDATE',
  CUSTOMER_REQUEST_ADD_DOCUMENTS = 'CUSTOMER_REQUEST_ADD_DOCUMENTS',
  // eslint-disable-next-line max-len
  CUSTOMER_REQUEST_ADD_DOCUMENTS_SUCCESS = 'CUSTOMER_REQUEST_ADD_DOCUMENTS_SUCCESS',
  CUSTOMER_REQUEST_REMOVE_DOCUMENT = 'CUSTOMER_REQUEST_REMOVE_DOCUMENT',

  //STATS
  STATS_REQUEST_CUSTOMERS_TOTAL = 'STATS_REQUEST_CUSTOMERS_TOTAL',
  STATS_SET_CUSTOMERS_TOTAL = 'STATS_SET_CUSTOMERS_TOTAL',
  STATS_RESET_CUSTOMERS_TOTAL = 'STATS_RESET_CUSTOMERS_TOTAL',
}

export const enum AvailableTranslationLang {
  EN_GB = 'en-GB',
  EN = 'en-US',
  PT = 'pt',
  UK = 'uk',
  FR = 'fr',
  IE = 'ie',
  DE = 'de',
  CN = 'cn',
  RU = 'ru',
}

export const enum PropertyEnergyCertificate {
  A_THREE_PLUS = 1,
  A_TWO_PLUS = 2,
  A_ONE_PLUS = 3,
  A = 4,
  B = 5,
  C = 6,
  D = 7,
  E = 8,
  F = 9,
  G = 10,
}

export const enum FormInputType {
  EMAIL = 'email',
  TEXT = 'text',
}

export const enum FetchRequestType {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}
export const enum RequestHeader {
  CONTENT_TYPE = 'Content-Type',
  AUTHORIZATION = 'Authorization',
}

export const enum RequestHeaderValues {
  APPLICATION_JSON = 'application/json',
}

export const enum ResponseStatusCode {
  BAD_REQUEST = 400,
  INTERNAL_ERROR = 500,
}

export const enum RequestsType {
  SERVICES = 'services',
  REQUEST = 'request',
  SELL = 'sell',
  VALUATION = 'valuation',
  ASK_FOR_VIEWING = 'ask-for-viewing',
}

export const enum PropertyType {
  LONG_TERM = 'long-term',
  SECRET = 'secret',
  ARCHIVE = 'archive',
  ON_THE_MARKET = 'on-the-market',
  PRIVATE = 'private',
  ONLY_FOR_CUSTOMERS = 'only-for-customers',
  ONLY_FOR_AGENTS = 'only-for-agents',
  SOLD = 'sold',
}

export const enum CertificateType {
  A_PLUS_PLUS_PLUS = 'a-three-plus',
  A_PLUS_PLUS = 'a-two-plus',
  A_PLUS = 'a-plus',
  A = 'a',
  B = 'b',
  C = 'c',
  D = 'd',
  E = 'e',
  F = 'f',
  G = 'g',
}

export const enum MessageType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const enum ManagerRole {
  ROOT = 0,
}
