export function SearchInput(): JSX.Element {
  return (
    <div className="search-2">
      <img
        src={require('../assets/svg/icons/search.svg').default}
        alt="search"
      />
      <input type="text" placeholder="Search" />
    </div>
  );
}
