import { useState } from 'react';

import Modal from '@components/modal';

type AgencyCardSocialProps = {
  agency: Agency;
  removeAgency: (payload: Agency) => DispatchWithPayload<Agency>;
};

export function AgencyCardSocial({
  agency,
  removeAgency,
}: AgencyCardSocialProps): JSX.Element {
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  return (
    <>
      <div className="data-item__person-social-data">
        {agency.instagramUrl && (
          <a
            href={agency.instagramUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              /*@ts-ignore*/
              window.open(agency.instagramUrl, '_blank');
            }}
          >
            <img
              src={require('@assets/svg/icons/instagram.svg').default}
              alt="instagram"
            />
          </a>
        )}
        {agency.website && (
          <a
            href={agency.website}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              /*@ts-ignore*/
              window.open(agency.website, '_blank');
            }}
          >
            <img
              src={require('@assets/svg/icons/global.svg').default}
              alt="website"
            />
          </a>
        )}
        {agency.phoneNumber && (
          <a
            href={`tel:${agency.phoneNumber}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.location.href = `tel:${agency.phoneNumber}`;
            }}
          >
            <img
              src={require('@assets/svg/icons/network.svg').default}
              alt="phone"
            />
          </a>
        )}
        {agency.facebookUrl && (
          <a
            href={agency.facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              /*@ts-ignore*/
              window.open(agency.facebookUrl, '_blank');
            }}
          >
            <img
              src={require('@assets/svg/icons/facebook.svg').default}
              alt="website"
            />
          </a>
        )}
        {agency.email && (
          <a
            href={`mailto:${agency.email}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.location.href = `mailto:${agency.email}`;
            }}
          >
            <img
              src={require('@assets/svg/icons/sent.svg').default}
              alt="email"
            />
          </a>
        )}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setShowRemoveModal(true);
          }}
        >
          <img
            src={require('@assets/svg/icons/delete.svg').default}
            alt="delete"
          />
        </a>
      </div>

      {showRemoveModal && (
        <Modal onClose={() => setShowRemoveModal(false)}>
          <p>Are you sure you want to remove this agency?</p>
          <div className="modal-buttons">
            <button
              className="modal-button modal-button--confirm"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
                removeAgency(agency);
              }}
            >
              Yes
            </button>
            <button
              className="modal-button"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowRemoveModal(false);
              }}
            >
              No
            </button>
          </div>
        </Modal>
      )}
    </>
  );
}
