import { useRef, useState } from 'react';

import { FormInput } from '@components/form_input';

export const Note = ({ user, updateCustomer }: any) => {
  const [note, setNote] = useState<string>(user.note || '');
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const onNoteChanged = async (name: string, value: string) => {
    setNote(value);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      updateCustomer({
        id: user.id,
        note: value,
      });
    }, 1000);
  };

  return (
    <div className="note-container">
      <FormInput
        type={'text'}
        name={'note'}
        value={note}
        /*@ts-ignore*/
        onValueChanged={(_, value) => onNoteChanged('note', value)}
        placeholder="Please leave a note..."
      />
    </div>
  );
};
