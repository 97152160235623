import { useState } from 'react';

import Spinner from '@components/spinner';

import { AgentContent } from './agent_content';
import { AgentInfo } from './agent_info';
import { AgentsCardSocial } from './agents_card_social';
import { AgentsForm } from './agents_form';

type AgentsCardProps = {
  agent: Agent;
  removeAgentAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  removeAgent: (payload: ExactPartner) => DispatchWithPayload<ExactPartner>;
  addAgentAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  updateAgent: (payload: Agent) => DispatchWithPayload<Agent>;
  agencies: Agency[];
  addAgentsDocument: (payload: Agent) => DispatchWithPayload<Agent>;
  removeAgentDocument: (
    payload: ExactPartner,
  ) => DispatchWithPayload<ExactPartner>;
};

export function AgentsCard({
  agent,
  removeAgent,
  addAgentAvatar,
  removeAgentAvatar,
  updateAgent,
  agencies,
  addAgentsDocument,
  removeAgentDocument,
}: AgentsCardProps): JSX.Element {
  const [isOpened, setIsOpened] = useState<AccordionState>({
    main: false,
    docs: false,
    preferences: false,
    note: false,
    history: false,
  });
  const handleToggleSection = (section: AccordionSection) => {
    setIsOpened((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };
  return (
    <>
      <div className="agents-card__layout">
        <div className={`main-section ${isOpened.main ? 'open' : ''}`}>
          <div className="head" onClick={() => handleToggleSection('main')}>
            <div className="head-user">
              <img
                src={require('@assets/svg/icons/arrow-down.svg').default}
                alt="arrow-down"
                className={`arrow-icon ${isOpened.main ? 'opened' : ''}`}
              />
              <AgentInfo agent={agent} />
            </div>
            <AgentsCardSocial agent={agent} removeAgent={removeAgent} />
          </div>
          <div className={`data-content ${isOpened.main ? 'open' : ''}`}>
            {isOpened.main && (
              <>
                <div>
                  <AgentsForm
                    agent={agent}
                    agencies={agencies}
                    addAgentAvatar={addAgentAvatar}
                    removeAgentAvatar={removeAgentAvatar}
                    updateAgent={updateAgent}
                  />
                </div>
                <AgentContent
                  isOpened={isOpened}
                  agent={agent}
                  handleToggleSection={handleToggleSection}
                  addAgentsDocument={addAgentsDocument}
                  removeAgentDocument={removeAgentDocument}
                  updateAgent={updateAgent}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
