import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 300,
      width: '100%',
    },
  },
};

function getStyles(option: any, selectedOptions: any[], theme: Theme) {
  return {
    fontWeight: selectedOptions.some((selected) => selected?.id === option?.id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelect({
  title,
  name,
  options,
  onChange,
  checkBox,
  defaultValue,
}: any) {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState<any[]>(
    defaultValue?.map((item: any) => item.id) || [],
  );
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(value as any);
    /*@ts-ignore*/
    onChange(
      name,
      /*@ts-ignore*/
      options.filter((v) => value.includes(v.id)),
    );
  };

  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
      <FormControl sx={{ borderRadius: '8px' }}>
        <InputLabel
          sx={{ display: 'flex', justifyContent: 'center', top: '-3px' }}
          id="demo-multiple-object-label"
        >
          {title}
        </InputLabel>
        <Select
          labelId="demo-multiple-object-label"
          id="demo-multiple-object"
          multiple
          autoWidth
          className="select-box test-select-class"
          value={selectedOptions}
          onChange={handleChange}
          style={{ borderRadius: '8px' }}
          input={
            <OutlinedInput
              style={{ padding: '8px 12px !important' }}
              label={title}
            />
          }
          renderValue={(selected) => {
            /*@ts-ignore*/
            return (
              selected
                /*@ts-ignore*/
                .reduce((acc, item) => {
                  /*@ts-ignore*/
                  const findOption = options.find(({ id }) => id === item);
                  return findOption ? [...acc, findOption.name] : acc;
                  /*@ts-ignore*/
                }, [])
                /*@ts-ignore*/
                .join(', ')
            );
          }}
          MenuProps={MenuProps}
        >
          {options.map((option: any) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={getStyles(option, selectedOptions, theme)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  {option?.icon && (
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                        objectFit: 'cover',
                        borderRadius: '50%',
                      }}
                      src={option.icon}
                    />
                  )}
                  <div>{option.name}</div>
                </div>
                {checkBox && (
                  <input
                    readOnly
                    type={'checkbox'}
                    checked={
                      !!selectedOptions.some(
                        (selected) => selected === option.id,
                      )
                    }
                  />
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
