import { useRef, useState } from 'react';

import { FileUploader } from '@components/file_uploader/file_uploader';
import { FormInput } from '@components/form_input';
import { PhotoDataButton } from '@components/photo_data_button';
import { adaptAgencyToSend } from '@utils/helpers';

type AgencyFormProps = {
  agency: Agency;
  addAgencyAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  removeAgencyAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  updateAgency: (payload: Agency) => DispatchWithPayload<Agency>;
};

export function AgencyForm({
  agency,
  addAgencyAvatar,
  removeAgencyAvatar,
  updateAgency,
}: AgencyFormProps): JSX.Element {
  const timeoutRef = useRef<number | null>(null);
  const [editAgency, setEditAgency] = useState<Agency>({
    id: agency.id,
    type: agency.type,
    name: agency.name || undefined,
    email: agency.email || undefined,
    website: agency.website || undefined,
    instagramUrl: agency.instagramUrl || undefined,
    facebookUrl: agency.facebookUrl || undefined,
    refSymbols: agency.refSymbols || undefined,
    nationality: agency.nationality || undefined,
    phoneNumber: agency.phoneNumber || undefined,
  });

  const handleFileUpload = (file: File | File[]) => {
    addAgencyAvatar({ type: agency.type, id: agency.id, avatar: file });
  };

  const onEditAgencyChanged = async (name: string, value: any) => {
    let newValue = value;

    const editedAgency = { ...editAgency, [name]: newValue };
    setEditAgency(editedAgency);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    /*@ts-ignore*/
    timeoutRef.current = setTimeout(() => {
      updateAgency(adaptAgencyToSend(editedAgency));
    }, 2000);
  };

  return (
    <form className="agents-form-container">
      {/*fileUploader*/}
      <div>
        {!agency.avatar ? (
          <FileUploader
            className="file-uploader"
            onFileUpload={handleFileUpload}
            children={
              <PhotoDataButton
                label={'UPLOAD AVATAR'}
                icon={require('@assets/svg/icons/upload.svg').default}
              />
            }
          />
        ) : (
          <PhotoDataButton
            label="REMOVE AVATAR"
            icon={require('@assets/svg/icons/remove.svg').default}
            onClick={() =>
              removeAgencyAvatar({ type: agency.type, id: agency.id })
            }
          />
        )}
      </div>
      {/*fullName*/}
      <div>
        <FormInput
          type="text"
          label="Name"
          name="name"
          placeholder={editAgency?.name || ''}
          value={editAgency.name || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/*Email*/}
      <div>
        <FormInput
          type="text"
          label="Email"
          name="email"
          placeholder={editAgency?.email || ''}
          value={editAgency.email || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/* site */}
      <div>
        <FormInput
          widget={
            <>
              <img
                src={require('@assets/svg/icons/global.svg').default}
                alt="telegram"
              />
            </>
          }
          type="text"
          label="www.alamo.pt"
          name="website"
          placeholder={editAgency?.website || ''}
          value={editAgency.website || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/*InstagramUrl * */}
      <div>
        <FormInput
          widget={
            <>
              <img
                src={require('@assets/svg/icons/instagram.svg').default}
                alt="instagram"
              />
            </>
          }
          type="text"
          label="Tel"
          name="instagramUrl"
          placeholder={editAgency?.instagramUrl || ''}
          value={editAgency.instagramUrl || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      <div>
        {/*Nationality*/}
        <FormInput
          type="text"
          label="Nationality"
          name="nationality"
          placeholder={editAgency?.nationality || ''}
          value={editAgency.nationality || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/*Tel*/}
      <div>
        <FormInput
          type="number"
          label="Tel"
          name="phoneNumber"
          placeholder={editAgency?.phoneNumber || ''}
          value={editAgency.phoneNumber || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/*refSymbols * */}
      <div>
        <FormInput
          type="text"
          label="Ref Symbol"
          name="refSymbols"
          placeholder={editAgency?.refSymbols || ''}
          value={editAgency.refSymbols || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
      {/*facebookUrl * */}
      <div>
        <FormInput
          widget={
            <>
              <img
                src={require('@assets/svg/icons/facebook.svg').default}
                alt="instagram"
              />
            </>
          }
          type="text"
          label="Tel"
          name="facebookUrl"
          placeholder={editAgency?.facebookUrl || ''}
          value={editAgency.facebookUrl || ''}
          onValueChanged={onEditAgencyChanged}
        />
      </div>
    </form>
  );
}
