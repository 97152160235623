import { useState } from 'react';

import { Docs } from '@components/sections/docs';
import { PreferencesContainer } from '@components/sections/preferences_container';

import { Note } from './sections/note';

const sections = ['docs', 'preferences', 'note', 'history'];

export const CustomerContent = ({
  isOpened,
  handleToggleSection,
  customer,
  addCustomerDocument,
  removeCustomerDocument,
  updateCustomer,
}: any) => {
  const [addNewPreference, setAddNewPreference] = useState(false);

  const handleAddNewPreference = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setAddNewPreference(true);
  };

  const resetAddNewPreferenceFlag = () => {
    setAddNewPreference(false);
  };

  return (
    <>
      {sections.map((section) => (
        <div
          key={section}
          className={`section ${isOpened[section] ? 'open' : ''} ${
            section === 'history' ? 'disabled' : ''
          }`}
        >
          <div
            className={`section-header ${
              section === 'history' ? 'disabled' : ''
            }`}
            onClick={() =>
              section !== 'history' && handleToggleSection(section)
            }
          >
            <img
              src={require('@assets/svg/icons/arrow-down.svg').default}
              alt="arrow-down"
              className={`arrow-icon ${isOpened[section] ? 'opened' : ''}`}
            />
            <span className="section-title">
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </span>
            {isOpened[section] && section === 'preferences' && (
              <button className="add-button" onClick={handleAddNewPreference}>
                <img src={require('@assets/svg/icons/plus.svg').default} />
                ADD
              </button>
            )}
          </div>
          <div className={`section-content ${isOpened[section] ? 'open' : ''}`}>
            {renderSectionContent(
              section,
              customer,
              addCustomerDocument,
              removeCustomerDocument,
              updateCustomer,
              addNewPreference,
              resetAddNewPreferenceFlag,
            )}
          </div>
        </div>
      ))}
    </>
  );
};

const renderSectionContent = (
  section: any,
  customer: Customer,
  addCustomerDocument: any,
  removeCustomerDocument: any,
  updateCustomer: any,
  addNewPreference: boolean,
  resetAddNewPreferenceFlag: () => void,
) => {
  switch (section) {
    case 'docs':
      return (
        <Docs
          title="Passport*"
          user={customer}
          addUserDocument={addCustomerDocument}
          removeDocument={removeCustomerDocument}
        />
      );
    case 'preferences':
      return (
        <PreferencesContainer
          user={customer}
          onUpdate={updateCustomer}
          addNewPreference={addNewPreference}
          resetAddNewPreferenceFlag={resetAddNewPreferenceFlag}
        />
      );
    case 'note':
      return <Note user={customer} updateCustomer={updateCustomer} />;
    case 'history':
      return (
        <div className="disabled-content">History content is disabled</div>
      );
    default:
      return null;
  }
};
