import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { showToast } from '@components/toast';
import { ApiRequests } from '@core/api_requests';
import { MessageType } from '@project/enums';
import { AgentsAction } from '@store/items/agents';
import { AppActions } from '@store/items/app';
import { updateAgentOrAgency, filterAgentOrAgency } from '@utils/helpers';

export function* getAllAgentsAndAgencies() {
  yield put(AppActions.lockUI());

  try {
    const result: { view: (Agent | Agency)[] } = yield call(
      ApiRequests.getAllAgentsRequest,
    );

    yield put(AgentsAction.setAgentsAndAgencies(result.view));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

const agentsSelector = (state: RootState): (Agent | Agency)[] => {
  return state.agents.agents;
};

export function* createNewAgent(
  action: DispatchWithPayload<CreateNewAgentDto>,
): SagaIterator {
  yield put(AppActions.lockUI());

  try {
    const newAgent: { agent: Agent } | ApiMessage = yield call(
      ApiRequests.createNewAgentRequest,
      action.payload,
    );
    if ((newAgent as ApiMessage).message) {
      throw (newAgent as ApiMessage).message;
    }

    const agents = yield select(agentsSelector);
    const newAgents = [(newAgent as { agent: Agent }).agent, ...agents];
    yield put(AgentsAction.setAgentsAndAgencies(newAgents));
    showToast({
      type: MessageType.SUCCESS,
      message: 'agents was created',
    });
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: 'An error occurred',
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* removeAgentById(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactAgentOrAgency | ApiMessage = yield call(
      ApiRequests.deleteAgentById,
      action.payload,
    );
    const { id, type } = action.payload;
    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = filterAgentOrAgency(agents, { id, type });
    yield put(AgentsAction.setAgentsAndAgencies(newAgents));

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateAgentAvatarById(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: { agent: Agent } = yield call(
      ApiRequests.addAgentAvatar,
      action.payload,
    );

    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = updateAgentOrAgency(agents, result.agent);

    yield put(AgentsAction.updateAgentAvatarSuccess(newAgents));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeAgentAvatar(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: Agent = yield call(
      ApiRequests.deleteAgentAvatarById,
      action.payload,
    );
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateAgentById(action: DispatchWithPayload<Agent>) {
  yield put(AppActions.lockUI());

  try {
    const result: { agent: Agent } = yield call(
      ApiRequests.updateAgentFormRequest,
      action.payload,
    );

    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = updateAgentOrAgency(agents, {
      id: result.agent.id,
      type: result.agent.type,
    });
    yield put(AgentsAction.updateAgentSuccess(newAgents));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* AddAgentsDocumentsById(action: DispatchWithPayload<Agent>) {
  yield put(AppActions.lockUI());
  try {
    const result: { agent: Agent } | ApiMessage = yield call(
      ApiRequests.addAgentDocumentsRequest,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = updateAgentOrAgency(
      agents,
      (result as { agent: Agent }).agent,
    );

    yield put(AgentsAction.addAgentsDocumentsSuccess(newAgents));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeAgentDocument(
  action: DispatchWithPayload<ExactPartner>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactPartner | ApiMessage = yield call(
      ApiRequests.deleteAgentDocumentById,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

//AGENCY

export function* createNewAgency(
  action: DispatchWithPayload<CreateNewAgencyDto>,
): SagaIterator {
  yield put(AppActions.lockUI());

  try {
    const newAgency: { agency: Agency } | ApiMessage = yield call(
      ApiRequests.createNewAgencyRequest,
      action.payload,
    );

    if ((newAgency as ApiMessage).message) {
      throw (newAgency as ApiMessage).message;
    }
    const agencies = yield select(agentsSelector);
    const newAgencies = [(newAgency as { agency: Agency }).agency, ...agencies];
    yield put(AgentsAction.setAgentsAndAgencies(newAgencies));
    showToast({
      type: MessageType.SUCCESS,
      message: 'Agency was created',
    });
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: 'An error occurred',
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  }
  yield put(AppActions.unlockUI());
}

export function* removeAgencyById(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactAgentOrAgency | ApiMessage = yield call(
      ApiRequests.deleteAgencyById,
      action.payload,
    );
    const { id, type } = action.payload;
    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = filterAgentOrAgency(agents, { id, type });
    yield put(AgentsAction.setAgentsAndAgencies(newAgents));

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateAgencyAvatarById(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: { agency: Agency } = yield call(
      ApiRequests.addAgencyAvatar,
      action.payload,
    );
    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgents = updateAgentOrAgency(agents, result.agency);
    yield put(AgentsAction.updateAgencyAvatarSuccess(newAgents));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeAgencyAvatar(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: Agency = yield call(
      ApiRequests.deleteAgencyAvatarById,
      action.payload,
    );
  } catch (error) {
    showToast({
      type:
        error === 'AGENCY_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* updateAgencyById(action: DispatchWithPayload<Agency>) {
  yield put(AppActions.lockUI());

  try {
    const result: { agency: Agency } = yield call(
      ApiRequests.updateAgencyFormRequest,
      action.payload,
    );
    const agents: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgency = updateAgentOrAgency(agents, {
      id: result.agency.id,
      type: result.agency.type,
    });
    yield put(AgentsAction.updateAgencySuccess(newAgency));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* AddAgencyDocumentsById(
  action: DispatchWithPayload<UpdateExactPartnerDto>,
) {
  yield put(AppActions.lockUI());
  try {
    const result: { agency: Agency } | ApiMessage = yield call(
      ApiRequests.addAgencyDocumentsRequest,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }

    const agencies: (Agent | Agency)[] = yield select(agentsSelector);
    const newAgencies = updateAgentOrAgency(
      agencies,
      (result as { agency: Agency }).agency,
    );

    yield put(AgentsAction.addAgencyDocumentsSuccess(newAgencies));
  } catch (e) {
    showToast({
      type: MessageType.ERROR,
      message: e as string,
    });

    if (e === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}

export function* removeAgencyDocument(
  action: DispatchWithPayload<ExactAgentOrAgency>,
) {
  yield put(AppActions.lockUI());

  try {
    const result: ExactAgentOrAgency | ApiMessage = yield call(
      ApiRequests.deleteAgencyDocumentById,
      action.payload,
    );

    if ((result as ApiMessage).message) {
      throw (result as ApiMessage).message;
    }
  } catch (error) {
    showToast({
      type:
        error === 'PARTNER_AVATAR_WAS_DELETED'
          ? MessageType.SUCCESS
          : MessageType.ERROR,
      message: error as string,
    });

    if (error === 'Unauthorized') {
      yield put(AgentsAction.resetData());
    }
  } finally {
    yield put(AppActions.unlockUI());
  }
}
