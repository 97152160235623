import dayjs from 'dayjs';
import { omit } from 'lodash';
import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FileUploader } from '@components/file_uploader/file_uploader';
import { FormInput } from '@components/form_input';
import { PhotoDataButton } from '@components/photo_data_button';

export function PartnerCardForm({
  partner,
  isOpened,
  docsWidget,
  preferencesWidget,
  updatePartner,
  removePartnerAvatar,
}: PartnerCardFormProps): JSX.Element {
  const timeoutRef = useRef<number | null>(null);

  const [editPartner, setEditPartner] = useState({
    id: partner?.id,
    fullName: partner?.fullName || '',
    email: partner?.email || '',
    birthday: partner?.birthday ? dayjs(partner.birthday).toDate() : '',
    company: partner?.company || '',
    nationality: partner?.nationality || '',
    phoneNumber: partner?.phoneNumber || '',
    whatsAppNumber: partner.whatsAppNumber || '',
    telegramUserName: partner?.telegramUserName || '',
  });

  const handleFileUpload = (file: File | File[]) => {
    onEditPartnerChanged('avatar', file);
  };

  const handleRemoveAvatar = () => {
    setEditPartner((prevState) => omit(prevState, 'avatar'));

    removePartnerAvatar({ id: partner.id });
  };

  const onEditPartnerChanged = async (name: string, value: any) => {
    let newValue = value;
    if (name === 'whatsAppNumber' || name === 'phoneNumber') {
      newValue = value.replace(/\s/g, '');
    }
    if (name === 'birthday' && value === null) {
      newValue = '';
    }
    const newPartner = { ...editPartner, [name]: newValue };
    setEditPartner(newPartner);
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    /*@ts-ignore*/
    timeoutRef.current = setTimeout(() => {
      updatePartner(newPartner);
    }, 2000);
  };

  return (
    <div className={`data-item__item-layout ${isOpened ? 'opened' : 'closed'}`}>
      <div className="data-item__controllers">
        {/*fileUploader*/}
        <div className="data-item__item-data">
          {!partner?.avatar && (
            <FileUploader
              className="file-uploader"
              onFileUpload={handleFileUpload}
              children={
                <PhotoDataButton
                  label={'UPLOAD AVATAR'}
                  icon={require('@assets/svg/icons/upload.svg').default}
                />
              }
            />
          )}
          {partner?.avatar && (
            <PhotoDataButton
              label="REMOVE AVATAR"
              icon={require('@assets/svg/icons/remove.svg').default}
              onClick={() => handleRemoveAvatar()}
            />
          )}
        </div>
        {/*fullName*/}
        <div className="data-item__item-data">
          <FormInput
            type="text"
            label="Full Name"
            name="fullName"
            placeholder={editPartner?.fullName}
            value={editPartner.fullName}
            onValueChanged={onEditPartnerChanged}
          />
          {/*Nationality*/}
          <FormInput
            type="text"
            label="Nationality"
            name="nationality"
            placeholder={partner?.nationality || ''}
            value={editPartner.nationality}
            onValueChanged={onEditPartnerChanged}
          />
        </div>
        <div className="data-item__item-data second-column">
          {/*Email*/}
          <FormInput
            type="text"
            label="Email"
            name="email"
            placeholder={editPartner?.email}
            value={editPartner.email}
            onValueChanged={onEditPartnerChanged}
          />
          {/*Tel*/}
          <FormInput
            type="text"
            label="Tel"
            name="phoneNumber"
            placeholder={editPartner?.phoneNumber || ''}
            value={editPartner.phoneNumber}
            onValueChanged={onEditPartnerChanged}
          />
        </div>

        <div className="data-item__item-data">
          {/*birthday*/}
          <DatePicker
            className="form-input"
            showIcon
            icon={
              <img
                src={require('@assets/svg/icons/birthday.svg').default}
                alt="birthday"
              />
            }
            /*@ts-ignore*/
            selected={editPartner.birthday || undefined}
            onChange={(date) => onEditPartnerChanged('birthday', date)}
          />
          {/*whatsapp*/}
          <FormInput
            type="text"
            widget={
              <>
                <img
                  src={require('@assets/svg/icons/whatsapp.svg').default}
                  alt="whatsapp"
                />
              </>
            }
            icon={require('@assets/svg/icons/pin.svg').default}
            name="whatsAppNumber"
            placeholder={editPartner?.whatsAppNumber || ''}
            value={editPartner.whatsAppNumber}
            onValueChanged={onEditPartnerChanged}
          />
        </div>
        <div className="data-item__item-data">
          {/*Company*/}
          <FormInput
            type="text"
            label="Company"
            name="company"
            placeholder={editPartner?.company || ''}
            value={editPartner.company}
            onValueChanged={onEditPartnerChanged}
          />
          <FormInput
            type="text"
            widget={
              <>
                <img
                  src={require('@assets/svg/icons/telegram.svg').default}
                  alt="telegram"
                />
              </>
            }
            name="telegramUserName"
            placeholder={editPartner?.telegramUserName || ''}
            value={editPartner.telegramUserName}
            onValueChanged={onEditPartnerChanged}
          />
        </div>
      </div>
      {docsWidget ? docsWidget : null}
      {preferencesWidget ? preferencesWidget : null}
    </div>
  );
}
