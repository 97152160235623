import { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { FileUploader } from '@components/file_uploader/file_uploader';
import { FormInput } from '@components/form_input';
import { PhotoDataButton } from '@components/photo_data_button';
import Select from '@components/selects/single_select';
import { adaptAgentToSend } from '@utils/helpers';

type AgentsFormProps = {
  addAgentAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  agent: Agent;
  removeAgentAvatar: (
    payload: ExactAgentOrAgency,
  ) => DispatchWithPayload<ExactAgentOrAgency>;
  updateAgent: (payload: Agent) => DispatchWithPayload<Agent>;
  agencies?: Agency[];
};

export function AgentsForm({
  addAgentAvatar,
  agent,
  removeAgentAvatar,
  updateAgent,
  agencies,
}: AgentsFormProps): JSX.Element {
  const timeoutRef = useRef<number | null>(null);
  const [editAgent, setEditAgent] = useState<Agent>({
    id: agent?.id,
    type: agent?.type,
    fullName: agent?.fullName || undefined,
    email: agent?.email || undefined,
    birthday: agent?.birthday || undefined,
    nationality: agent?.nationality || undefined,
    phoneNumber: agent?.phoneNumber || undefined,
    whatsAppNumber: agent?.whatsAppNumber || undefined,
    telegramUserName: agent?.telegramUserName || undefined,
  });
  const handleFileUpload = (file: File | File[]) => {
    addAgentAvatar({ id: agent.id, type: agent.type, avatar: file });
  };

  const onEditAgentsChanged = async (name: string, value: any) => {
    let newValue = value;

    if (name === 'agencyId' && value && typeof value === 'object') {
      newValue = value.id;
    }

    const editedAgent = { ...editAgent, [name]: newValue };
    setEditAgent(editedAgent);

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
    /*@ts-ignore*/
    timeoutRef.current = setTimeout(() => {
      updateAgent(adaptAgentToSend(editedAgent));
    }, 2000);
  };

  const options = agencies?.map((agency: Agency) => ({
    id: agency?.id,
    value: agency?.name?.toLowerCase().replace(/\s+/g, '-') || '',
    label: agency.name || '',
    icon: agency.avatar || require('@assets/svg/icons/profile.svg').default,
  }));

  const remappedDefaultValue = (agency?: Agency) => {
    return {
      id: agency?.id,
      value: agency?.name?.toLowerCase().replace(/\s+/g, '-') || '',
      label: agency?.name || '',
      icon: agency?.avatar || require('@assets/svg/icons/profile.svg').default,
    };
  };
  return (
    <form className="agents-form-container">
      {/*fileUploader*/}
      <div>
        {!agent?.avatar ? (
          <FileUploader
            className="file-uploader"
            onFileUpload={handleFileUpload}
            children={
              <PhotoDataButton
                label={'UPLOAD AVATAR'}
                icon={require('@assets/svg/icons/upload.svg').default}
              />
            }
          />
        ) : (
          <PhotoDataButton
            label="REMOVE AVATAR"
            icon={require('@assets/svg/icons/remove.svg').default}
            onClick={() =>
              removeAgentAvatar({ id: agent.id, type: agent.type })
            }
          />
        )}
      </div>
      {/*fullName*/}
      <div>
        <Select
          name="agencyId"
          options={options}
          onChange={onEditAgentsChanged}
          onRemove={() => onEditAgentsChanged('agencyId', null)}
          defaultValue={remappedDefaultValue(agent.agency)}
        />
      </div>
      <div>
        <FormInput
          type="text"
          label="Full Name"
          name="fullName"
          placeholder={editAgent?.fullName || ''}
          value={editAgent.fullName || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
      {/*Email*/}
      <div>
        <FormInput
          type="text"
          label="Email"
          name="email"
          placeholder={editAgent?.email || ''}
          value={editAgent.email || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
      {/*birthday*/}
      <div>
        <DatePicker
          className="form-input"
          showIcon
          icon={
            <img
              src={require('@assets/svg/icons/birthday.svg').default}
              alt="birthday"
            />
          }
          /*@ts-ignore*/
          selected={editAgent.birthday || undefined}
          onChange={(date) => onEditAgentsChanged('birthday', date)}
        />
      </div>
      {/*Tel*/}
      <div>
        <FormInput
          type="number"
          label="Tel"
          name="phoneNumber"
          placeholder={editAgent?.phoneNumber || ''}
          value={editAgent.phoneNumber || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
      <div>
        {/*Nationality*/}
        <FormInput
          type="text"
          label="Nationality"
          name="nationality"
          placeholder={editAgent?.nationality || ''}
          value={editAgent.nationality || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
      <div>
        {/*whatsapp*/}
        <FormInput
          type="number"
          widget={
            <>
              <img
                src={require('@assets/svg/icons/whatsapp.svg').default}
                alt="whatsapp"
              />
            </>
          }
          icon={require('@assets/svg/icons/pin.svg').default}
          name="whatsAppNumber"
          placeholder={editAgent?.whatsAppNumber || ''}
          value={editAgent.whatsAppNumber || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
      {/*telegram*/}
      <div>
        <FormInput
          type="text"
          widget={
            <>
              <img
                src={require('@assets/svg/icons/telegram.svg').default}
                alt="telegram"
              />
            </>
          }
          name="telegramUserName"
          placeholder={editAgent?.telegramUserName || ''}
          value={editAgent.telegramUserName || ''}
          onValueChanged={onEditAgentsChanged}
        />
      </div>
    </form>
  );
}
