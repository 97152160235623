import { Dispatch, SetStateAction, useState } from 'react';

import { FormInput } from '@components/form_input';
import Select, { SelectOption } from '@components/selects/single_select';

type Props = {
  isSwitched: boolean;
  setIsSwitched: Dispatch<SetStateAction<boolean>>;
  onCreateNewAgentChanged: (
    name: string,
    value: string | SelectOption | null,
  ) => Promise<void>;
  newAgent?: CreateNewAgentDto | CreateNewAgencyDto;
  agencies: Agency[];
};

export function AgentSwitcher({
  isSwitched,
  setIsSwitched,
  onCreateNewAgentChanged,
  newAgent,
  agencies,
}: Props): JSX.Element {
  const options = agencies?.map((agency) => ({
    id: agency.id,
    value: agency?.name?.toLowerCase().replace(/\s+/g, '-') || '',
    label: agency.name || '',
    icon: agency.avatar || require('@assets/svg/icons/profile.svg').default,
  }));

  return (
    <div className="switcher">
      <FormInput
        type="text"
        extraClass={`${isSwitched ? 'switch' : ''}`}
        label="Ref Symbol"
        placeholder="KON"
        name={'refSymbols'}
        value={(newAgent as CreateNewAgencyDto)?.refSymbols || ''}
        onValueChanged={(name: string, value: string) => {
          if (onCreateNewAgentChanged) {
            onCreateNewAgentChanged(name, value);
          }
        }}
      />
      <div
        className={`switcher__button ${isSwitched ? 'switch' : ''}`}
        onClick={setIsSwitched.bind(null, !isSwitched)}
      >
        <img
          src={require('@assets/svg/icons/switcher.svg').default}
          alt="switcher"
        />
        <span>{isSwitched ? 'AGENCY' : 'AGENT'}</span>
      </div>
      {!isSwitched && (
        <div className="data-item-dropdown">
          <Select
            name="agencyId"
            options={options}
            onRemove={() => onCreateNewAgentChanged('agencyId', null)}
            onChange={(name: string, selectedOption: SelectOption) => {
              onCreateNewAgentChanged(name, selectedOption);
            }}
            defaultTitle={'No agency'}
          />
        </div>
      )}
    </div>
  );
}
