import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import * as React from 'react';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      maxWidth: 400,
    },
  },
};

function getStyles(option: any, selectedOptions: any[], theme: Theme) {
  return {
    fontWeight: selectedOptions.some((selected) => selected.id === option.id)
      ? theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function AgentsMultipleSelect({
  title,
  name,
  options,
  selectOnChange,
  checkBox,
  defaultValue,
}: any) {
  const theme = useTheme();
  const [selectedOptions, setSelectedOptions] = React.useState<any[]>(
    defaultValue?.map((item: any) => item.id) || [],
  );
  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    /*@ts-ignore*/
    setSelectedOptions(value);
    /*@ts-ignore*/
    selectOnChange(
      name,
      /*@ts-ignore*/
      options.filter((v) => value.includes(v.id)),
    );
  };
  return (
    <div style={{ display: 'grid', gridTemplateColumns: '1fr', gap: '16px' }}>
      <FormControl sx={{ width: '100%', borderRadius: '8px' }}>
        <InputLabel
          sx={{ display: 'flex', justifyContent: 'center', top: '-3px' }}
          id="demo-multiple-object-label"
        >
          {title}
        </InputLabel>
        <Select
          labelId="demo-multiple-object-label"
          id="demo-multiple-object"
          autoWidth
          multiple
          className="select-box test-select-class"
          value={selectedOptions}
          onChange={handleChange}
          input={
            <OutlinedInput
              style={{ padding: '8px 12px !important' }}
              label={title}
            />
          }
          renderValue={(selected) =>
            /*@ts-ignore*/
            selected
              /*@ts-ignore*/
              .reduce((acc, item) => {
                /*@ts-ignore*/
                const findOption = options.find(({ id }) => id === item);
                return findOption ? [...acc, findOption.name] : acc;
                /*@ts-ignore*/
              }, [])
              /*@ts-ignore*/
              .join(', ')
          }
          MenuProps={MenuProps}
          style={{ width: '100%', borderRadius: '8px' }} // Подстраиваем ширину
        >
          {options?.map((option: any) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={getStyles(option, selectedOptions, theme)}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '4px' }}
                >
                  {option?.icon && (
                    <img
                      style={{
                        width: '20px',
                        height: '20px',
                        borderRadius: '50%',
                      }}
                      src={option.icon}
                      alt={option.name}
                    />
                  )}
                  <div>{option.name}</div>
                  <div>
                    <div
                      className={
                        option.type === 1
                          ? 'person-data__role'
                          : 'person-data__role_violet'
                      }
                    >
                      {option.type === 1 ? 'Agent' : 'Agency'}
                    </div>
                  </div>
                </div>
                {checkBox && (
                  <input
                    readOnly
                    type="checkbox"
                    checked={
                      !!selectedOptions.some(
                        (selected) => selected === option.id,
                      )
                    }
                  />
                )}
              </div>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
