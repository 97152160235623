import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Navbar } from '@components/navbar';
import MultipleSelect from '@components/selects/multiple_select';
import Spinner from '@components/spinner';
import { AgentsAction } from '@store/items/agents';
import { CustomersAction } from '@store/items/customers';
import { CustomersCard } from 'features/customers/customers_components/customers_card';
import { CustomersPanel } from 'features/customers/customers_components/customers_panel';
import { DataNotFound } from 'features/partners/partners_components/data_not_found';

type Filters = {
  type: { id: number; name: string } | null;
};

const mapState = ({ customers, agents }: RootState) => ({
  customers: customers.customers,
  agentsAndAgencies: agents.agents,
  isLoading: customers.isLoading,
});

const mapDispatch = {
  getAllCustomers: CustomersAction.getCustomers,
  getAllAgentsAndAgencies: AgentsAction.getAgents,
  createNewCustomer: CustomersAction.CreateNewCustomerRequest,
  removeCustomer: CustomersAction.removeCustomer,
  addCustomerAvatar: CustomersAction.addCustomerAvatar,
  removeCustomerAvatar: CustomersAction.removeCustomerAvatar,
  updateCustomer: CustomersAction.updateCustomer,
  addCustomerDocument: CustomersAction.addCustomerDocuments,
  removeCustomerDocument: CustomersAction.removeCustomerDocument,
};

export function Component({
  customers,
  agentsAndAgencies,
  getAllAgentsAndAgencies,
  isLoading,
  getAllCustomers,
  createNewCustomer,
  removeCustomer,
  addCustomerAvatar,
  removeCustomerAvatar,
  updateCustomer,
  addCustomerDocument,
  removeCustomerDocument,
}: CustomersPageProps): JSX.Element {
  useEffect(() => {
    getAllCustomers();
    getAllAgentsAndAgencies();
  }, []);

  const [searchTerm, setSearchTerm] = useState({
    search: '',
  });
  const [filteredCustomers, setFilteredCustomers] = useState<Customer[]>([]);
  const [filters, setFilters] = useState<Filters>({
    type: null,
  });

  const handleFilterChange = (
    key: string,
    selectedOption: DropDownItem | null,
  ) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: selectedOption,
    }));
  };

  const applyFilters = (items: Customer[], filters: Filters): Customer[] => {
    return items.filter((item) => {
      if (filters.type) {
        return item?.types?.some((type) => type.id === filters.type?.id);
      }
      return true;
    });
  };

  useEffect(() => {
    let filteredList = applyFilters(customers || [], filters);

    if (searchTerm.search) {
      filteredList = filteredList.filter((item) =>
        item?.types?.some((type) => type.id === 1)
          ? (item as Agent)?.fullName
              ?.toLowerCase()
              .includes(searchTerm.search.toLowerCase())
          : (item as Agency)?.name
              ?.toLowerCase()
              .includes(searchTerm.search.toLowerCase()),
      );
    }

    setFilteredCustomers(filteredList);
  }, [filters, searchTerm, customers]);

  const onSearchChanged = (name: string, value: string) => {
    setSearchTerm({ ...searchTerm, [name]: value });
  };

  const remappedAgents = agentsAndAgencies?.map((option: any) => {
    return {
      id: option?.id,
      value:
        option?.name?.toLowerCase().replace(/\s+/g, '-') ||
        option?.fullName ||
        '',
      name: option?.name || option?.fullName || '',
      icon: option?.avatar || require('@assets/svg/icons/profile.svg').default,
      type: option?.type,
    };
  });

  const haveCustomers = filteredCustomers && filteredCustomers?.length < 1;

  return (
    <>
      <section className="admin-navbar">
        <Navbar />
      </section>
      <section>
        <CustomersPanel
          agentsAndAgencies={remappedAgents}
          createNewCustomer={createNewCustomer}
          searchTerm={searchTerm}
          onSearchChanged={onSearchChanged}
          handleFilterChange={handleFilterChange}
        />
      </section>
      <section>
        {isLoading && <Spinner />}

        {filteredCustomers?.length > 0
          ? filteredCustomers.map((customer) => (
              <CustomersCard
                key={customer.id}
                removeCustomerAvatar={removeCustomerAvatar}
                addCustomerAvatar={addCustomerAvatar}
                updateCustomer={updateCustomer}
                customer={customer}
                removeCustomer={removeCustomer}
                addCustomerDocument={addCustomerDocument}
                removeCustomerDocument={removeCustomerDocument}
              />
            ))
          : haveCustomers && (
              <DataNotFound
                subTitle={
                  haveCustomers && !!searchTerm.search
                    ? `There is no data by your request, try something else`
                    : `Here still no one Customer was added, let's try`
                }
              />
            )}
      </section>
    </>
  );
}

export const CustomersPageConnector = connect(mapState, mapDispatch);

export const CustomersPage = CustomersPageConnector(Component);
